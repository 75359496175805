import * as React from 'react';
import { ILoadMatchesParams, getMatches } from './utilities';
import {
  IMatchesList,
  ISportSectorPhasesItem,
  IWidgetMatchesListMatchItem,
} from '@sportnet/content/library/Competitions';
import {
  IWidgetMatchesList,
  IWidgetType,
} from '@sportnet/content/utilities/library';
import { __ } from '@sportnet/content/utilities/utilities';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem } from 'polished';
import Button from '@sportnet/ui/lib/Button';
import Loader from '@sportnet/ui/lib/Loader';
import MatchesList from './MatchesList';
import MonthSwitcher from '@sportnet/content/view/components/MonthSwitcher';
import NotFound from '@sportnet/ui/lib/NotFound';
import WidgetHeader from '@sportnet/content/view/components/Header';
import addMonths from 'date-fns/addMonths';
import endOfMonth from 'date-fns/endOfMonth';
import startOfMonth from 'date-fns/startOfMonth';
import styled, { css } from 'styled-components';

type IRenderEmpty = (type: IWidgetType) => React.ReactNode;

const AspectRatioWrapper = styled('div')<{ scWidth: number; scHeight: number }>`
  position: relative;
  width: 100%;
  padding-top: ${({ scWidth, scHeight }) => 100 / (scWidth / scHeight)}%;
`;

const AspectRatioContentWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: #fff;
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: ${rem(12)};
  ${({ theme }) => css`
    margin: 0 -${rem(theme.grid.gutterWidth / 2)};
    width: calc(100% + ${rem(theme.grid.gutterWidth)});
  `}
  ${mb('m')} {
    margin: 0;
    width: 100%;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Title = styled(WidgetHeader)`
  && {
    > h2 {
      font-size: ${rem(18)};
      font-weight: bold;
    }
  }
`;

const NotFoundWrapper = styled.div`
  text-align: center;
`;

const ErrorWrapper = styled.div`
  text-align: center;
  color: red;
`;

const LoadMoreWrapper = styled.div`
  margin-top: ${rem(16)};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const LoadMoreButton = styled(Button)`
  && {
    width: max-content;
    margin-top: ${rem(16)};
  }
`;

const LoadRoundButton = styled(Button)<{ $margin: 'top' | 'bottom' }>`
  background-color: #f1f1f1;
  margin: ${({ $margin, theme }) =>
    $margin === 'top'
      ? `${rem(8)} -${rem(theme.grid.gutterWidth / 2)} 0`
      : `0 -${rem(theme.grid.gutterWidth / 2)} ${rem(8)}`};
  min-height: ${rem(36)};
  width: ${({ theme }) => `calc(100% + ${rem(theme.grid.gutterWidth)})`};

  ${mb('m')} {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  &:hover {
    background-color: #e4e4e4;
  }

  span {
    margin-right: ${rem(4)};
  }
`;

export interface IMatchRound {
  _id: string;
  name: string;
  dateFrom: string;
  dateTo: string;
}

type IData = Omit<IWidgetMatchesList, 'data' | 'type'> & {
  type: string;
  data?: {
    matches: IWidgetMatchesListMatchItem[];
    sportSectorPhases: ISportSectorPhasesItem[];
    total: number;
    nextOffset: number | null;
    rounds?: IMatchRound[];
    activeRoundId?: string;
  };
};

interface IOwnProps {
  data: IData;
  renderEmpty?: IRenderEmpty;
}

const MatchesListWidget: React.FC<IOwnProps> = ({
  data: widgetData,
  renderEmpty,
}) => {
  const [date, setDate] = React.useState<Date | null>(null);
  const [matchesList, setMatchesList] = React.useState<IMatchesList>({
    matches: widgetData?.data?.matches || [],
    total: widgetData?.data?.total ?? 0,
    nextOffset: widgetData?.data?.nextOffset ?? null,
  });
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState('');
  const [isLoadMoreLoading, setIsLoadMoreLoading] = React.useState(false);
  const [prevRoundId, setPrevRoundId] = React.useState('');
  const [nextRoundId, setNextRoundId] = React.useState('');
  const [isLoadingNextRound, setIsLoadingNextRound] = React.useState(false);
  const [isLoadingPrevRound, setIsLoadingPrevRound] = React.useState(false);

  const teamIds = [widgetData.teamId, ...(widgetData.teamIds || [])].filter(
    Boolean
  );

  const handleLoadMoreClick = async () => {
    const params: ILoadMatchesParams = {};
    if (widgetData.showAsMonthlyCalendar) {
      const now = new Date();
      params.dateFrom = date ? startOfMonth(date) : startOfMonth(now);
      params.dateTo = date ? endOfMonth(date) : endOfMonth(now);
      params.sorter = 'dateFromAsc';
    }
    if (matchesList.nextOffset) {
      params.offset = matchesList.nextOffset;
    }

    try {
      setIsLoadMoreLoading(true);
      const resp = await getMatches(widgetData, params);
      setMatchesList((prev) => {
        return {
          ...resp,
          matches: [...prev.matches, ...resp.matches],
        };
      });
    } catch (err) {
      setError((err as any).message || err);
    } finally {
      setIsLoadMoreLoading(false);
    }
  };

  const handleLoadNextRound = async () => {
    if (nextRoundId && widgetData.data?.rounds) {
      setIsLoadingNextRound(true);

      try {
        const resp = await getMatches(
          { ...widgetData, roundId: nextRoundId },
          {}
        );

        setMatchesList((prev) => ({
          matches: [...prev.matches, ...resp.matches],
          total: resp.total,
          nextOffset: resp.nextOffset,
        }));

        const roundIndex = widgetData.data.rounds.findIndex(
          (round) => round._id === nextRoundId
        );

        if (roundIndex + 1 < widgetData.data.rounds.length) {
          setNextRoundId(widgetData.data.rounds[roundIndex + 1]._id);
        } else {
          setNextRoundId('');
        }
      } catch (err) {
        setError((err as any).message || err);
      } finally {
        setIsLoadingNextRound(false);
      }
    }
  };

  const handleLoadPrevRound = async () => {
    if (prevRoundId && widgetData.data?.rounds) {
      setIsLoadingPrevRound(true);

      try {
        const resp = await getMatches(
          { ...widgetData, roundId: prevRoundId },
          {}
        );

        setMatchesList((prev) => ({
          matches: [...resp.matches, ...prev.matches],
          total: resp.total,
          nextOffset: resp.nextOffset,
        }));

        const roundIndex = widgetData.data.rounds.findIndex(
          (round) => round._id === prevRoundId
        );

        if (roundIndex > 0) {
          setPrevRoundId(widgetData.data.rounds[roundIndex - 1]._id);
        } else {
          setPrevRoundId('');
        }
      } catch (err) {
        setError((err as any).message || err);
      } finally {
        setIsLoadingPrevRound(false);
      }
    }
  };

  const renderWidgetContent = () => (
    <Wrapper>
      {widgetData.title && (
        <TitleWrapper>
          <Title renderShowmoreLink={undefined}>{widgetData.title}</Title>
        </TitleWrapper>
      )}
      {widgetData.showAsMonthlyCalendar && (
        <MonthSwitcher date={date ?? new Date()} onMonthChange={setDate} />
      )}
      {isLoading ? (
        <LoaderWrapper>
          <Loader size="l" />
        </LoaderWrapper>
      ) : error ? (
        <ErrorWrapper>{error}</ErrorWrapper>
      ) : matchesList && matchesList.matches.length > 0 ? (
        <>
          {prevRoundId && (
            <LoadRoundButton
              $margin="bottom"
              block
              onClick={handleLoadPrevRound}
              loading={isLoadingPrevRound}
              disabled={isLoadingPrevRound}
              icon="arrow-top"
            >
              {__('Predchádzajúce kolo')}
            </LoadRoundButton>
          )}
          <MatchesList
            matchesList={matchesList}
            groupByRound={
              widgetData.type === 'roundMatchesList'
                ? true
                : widgetData.groupByRound
            }
          />
          {matchesList.nextOffset && (
            <LoadMoreWrapper>
              <LoadMoreButton
                primary
                loading={isLoadMoreLoading}
                onClick={handleLoadMoreClick}
              >
                {__('Zobraziť viac')}
              </LoadMoreButton>
            </LoadMoreWrapper>
          )}
          {nextRoundId && (
            <LoadRoundButton
              $margin="top"
              block
              onClick={handleLoadNextRound}
              loading={isLoadingNextRound}
              disabled={isLoadingNextRound}
              icon="arrow-down"
            >
              {__('Ďalšie kolo')}
            </LoadRoundButton>
          )}
        </>
      ) : (
        <NotFoundWrapper>
          {__(
            'Pre zvolené kritériá, alebo časové obdobie, neboli nájdené žiadne zápasy.'
          )}
        </NotFoundWrapper>
      )}
    </Wrapper>
  );

  const ratio = widgetData.ratio ? widgetData.ratio.split(':') : null;

  React.useEffect(() => {
    (async () => {
      if (date) {
        setIsLoading(true);
        setError('');
        try {
          const dateFrom = startOfMonth(date);
          const dateTo = addMonths(dateFrom, 1);
          const resp = await getMatches(widgetData, {
            dateFrom,
            dateTo,
          });
          setMatchesList({
            matches: resp.matches,
            total: resp.total,
            nextOffset: resp.nextOffset,
          });
        } catch (err) {
          setError((err as any).message || err);
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [widgetData, date, setMatchesList, setIsLoading, setError]);

  React.useEffect(() => {
    if (
      widgetData.type === 'roundMatchesList' &&
      widgetData.data?.rounds &&
      widgetData.data?.activeRoundId
    ) {
      const rounds = widgetData.data.rounds;

      const activeRoundIndex = rounds.findIndex(
        (round) => round._id === widgetData.data!.activeRoundId
      );

      if (activeRoundIndex > 0) {
        setPrevRoundId(rounds[activeRoundIndex - 1]._id);
      }

      if (activeRoundIndex + 1 < rounds.length) {
        setNextRoundId(rounds[activeRoundIndex + 1]._id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    widgetData.data?.rounds,
    widgetData.data?.activeRoundId,
    widgetData.type,
  ]);

  if (!widgetData.competitionId && !teamIds.length && renderEmpty) {
    return <>{renderEmpty('matchesList')}</>;
  }

  if (!widgetData.data) {
    return null;
  }

  if (
    widgetData.type === 'roundMatchesList' &&
    !widgetData.data.activeRoundId
  ) {
    return (
      <NotFound title={__('Nenašlo sa aktívne kolo súťaže')} icon="error" />
    );
  }

  return (
    <div className="content-widget-matchesList">
      {ratio && ratio.length === 2 ? (
        <AspectRatioWrapper
          scWidth={Number(ratio[0])}
          scHeight={Number(ratio[1])}
        >
          <AspectRatioContentWrapper>
            {renderWidgetContent()}
          </AspectRatioContentWrapper>
        </AspectRatioWrapper>
      ) : (
        renderWidgetContent()
      )}
    </div>
  );
};

export default MatchesListWidget;
