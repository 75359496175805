const HOMEPAGE_URL = '/';

const NIKE = {
  API_BASE_URL: process.env.REACT_APP_NIKE_SAPI_BASE_URL,
  LOGO_TRACKING_BN_MOBILE: '70470246',
  LOGO_TRACKING_BN_DESKTOP: '70470245',
};

// App related config
export default {
  ACTIVE_SEASON_NAME: '2024/2025',
  ACTIVE_SEASON_ID: '2024-2025',
  ACTIVE_SEASON_NAMES: ['2024', '2024/2025'],
  ACTIVE_SEASON_IDS: ['2024', '2023-2025'],
  APP_ID: 'pages',
  MAX_WIDTH: 1232,
  CONTENT_MAX_WIDTH: 1232,
  PAVUK_COMPETITION_IDS: [
    '60b5416af293946277a49080',
    '5ee78b02de07959dc505106c',
    '6292a2cc71632936098e8af1',
    '64784fa27b634444d1943186',
    '665735cef8f64d2a884300f0',
  ] as string[],
  WIDER_CONTENT_MAX_WIDTH: 1232,
  CREATED_ARTICLE_DATE_TIME_FORMAT: "d. MMM yyyy 'o' HH:mm",
  MODIFIED_ARTICLE_DATE_TIME_FORMAT: "d. MMM yyyy 'o' HH:mm",
  DATE_FORMAT: 'dd.MM.yyyy',
  TIME_FORMAT: 'HH:mm',
  DATE_TIME_FORMAT: 'dd.MM.yyyy HH:mm',
  DATETIME_FORMAT_MINUTES_ONLY: 'dd.MM.yyyy\u00a0HH:mm',
  DEFAULT_CONTENT_DIVIDER: 'default',
  OWNER_PPO: 'futbalsfz.sk',
  OWNER_PPOS: ['futbalsfz.sk', 'issf_union_51'],
  SPORT_SECTOR: 'futbal',
  SPORT_SECTORS: ['futbal', 'futsal', 'beachfutbal'],
  LIST_RELATED_UNIONS: 'RELATED_UNIONS',
  LIST_OWNER_COMPETITIONS: 'OWNER_COMPETITIONS',
  HOMEPAGE_PAST_COMPETITION_MATCHES: 'HOMEPAGE_PAST_COMPETITION_MATCHES',
  HOMEPAGE_FUTURE_COMPETITION_MATCHES: 'HOMEPAGE_FUTURE_COMPETITION_MATCHES',
  PANEL_PAST_COMPETITION_MATCHES: 'PANEL_PAST_COMPETITION_MATCHES',
  PANEL_FUTURE_COMPETITION_MATCHES: 'PANEL_FUTURE_COMPETITION_MATCHES',
  PANEL_COMPETITION_MATCHES: 'PANEL_COMPETITION_MATCHES',
  PANEL_MAINEVENT_COMPETITION_MATCHES: 'PANEL_MAINEVENT_COMPETITION_MATCHES',
  LIST_COMPETITION_MATCHES_LIMIT: 8,
  LIST_TODAY_COMPETITION_MATCHES: 'LIST_TODAY_COMPETITION_MATCHES',
  LIST_PAST_COMPETITION_MATCHES: 'LIST_PAST_COMPETITION_MATCHES',
  LIST_FUTURE_COMPETITION_MATCHES: 'LIST_FUTURE_COMPETITION_MATCHES',
  LIST_COMPETITIONS_BY_SEASON_NAME: 'COMPETITIONS_BY_SEASON_NAME',
  LIST_COMPETITIONS: 'LIST_COMPETITIONS',
  LIST_COMPETITIONS_BY_GROUP_ID: 'COMPETITIONS_BY_GROUP_ID',
  LIST_COMPETITION_PARTS: 'COMPETITION_PARTS',
  LIST_PAST_COMPETITION_PART_MATCHES: 'PAST_COMPETITION_PART_MATCHES',
  LIST_PAST_COMPETITION_PART_MATCHES_LIMIT: 16,
  LIST_FUTURE_COMPETITION_PART_MATCHES: 'FUTURE_COMPETITION_PART_MATCHES',
  LIST_FUTURE_COMPETITION_PART_MATCHES_LIMIT: 16,
  LIST_TEAM_MATCHES: 'LIST_TEAM_MATCHES',
  LIST_PART_MATCHES: 'LIST_PART_MATCHES',
  ARTICLE_CONTENT_TYPE_SMARTTAG_KEY: '_Typ obsahu',
  ARTICLE_TYPE_SMARTTAG_KEY: '_Typ',
  VIDEO_ARTICLE_DOCTYPE: 'video',
  TOPIC_SECTION_ID: process.env.REACT_APP_TOPIC_SECTION_ID
    ? parseInt(process.env.REACT_APP_TOPIC_SECTION_ID)
    : 4807,
  TOPIC_SECTION_LAYOUT_NAME: 'tema',
  MMA_SECTION_LAYOUT_NAME: 'mma',
  EVENT_SECTION_LAYOUT_NAME: 'event',
  FUTBALNET_SECTION_ID: process.env.REACT_APP_FUTBALNET_SECTION_ID
    ? parseInt(process.env.REACT_APP_FUTBALNET_SECTION_ID)
    : 4521,
  DOXXBET_RESULTS_SECTION_LAYOUT_NAME: 'vysledky',
  DOXXBET_RESULTS_SECTION_UNIQUE_ID: 'vysledky',
  DOXXBET_DEFAULT_MENU_ICON_NAME: 'link',
  LIST_H2H_MATCHES: 'LIST_H2H_MATCHES',
  LIST_H2H_MATCHES_LIMIT: 5,
  LIST_MUTUAL_MATCHES: 'LIST_MUTUAL_MATCHES',
  LIST_MUTUAL_MATCHES_LIMIT: 5,
  LIST_LAST_HOMETEAM_MATCHES: 'LIST_LAST_HOMETEAM_MATCHES',
  LIST_LAST_HOMETEAM_MATCHES_LIMIT: 5,
  LIST_LAST_AWAYTEAM_MATCHES: 'LIST_LAST_AWAYTEAM_MATCHES',
  LIST_LAST_AWAYTEAM_MATCHES_LIMIT: 5,
  LIST_UNION_COMPETITIONS: 'LIST_UNION_COMPETITIONS',
  LIST_UNION_MATCHES: 'LIST_UNION_MATCHES',
  LIST_UNION_MATCHES_LIMIT: 8,
  NUMBER_OF_MAIN_SECTIONS: 7,
  LIST_CLUB_RESULTS: 'LIST_CLUB_RESULTS',
  LIST_CLUB_RESULTS_LIMIT: 8,
  LIST_CLUB_FIXTURES: 'LIST_CLUB_FIXTURES',
  LIST_CLUB_FIXTURES_LIMIT: 8,
  LIST_CLUB_COMPETITIONS: 'LIST_CLUB_COMPETITIONS',
  PREMIUM_LAYOUT_ID: 'premium',
  MATCH_LAYOUT_ID: 'match',
  MATCH_DOCTYPE_ID: 'match',
  ARTICLE_STATS_DELAY: 2000, // 2s
  FEEDITEM_STATS_DELAY: 2000, // 2s
  PREVIEW_ARTICLE_ID: 1,
  LIST_COMMISIONS: 'LIST_COMMISIONS',
  LIST_SEASONS: 'LIST_SEASONS',
  LIST_RESOLUTIONS: 'LIST_RESOLUTIONS',
  DISCLOSURE_YEAR_START: 2017,
  LIST_CREDIT_ITEMS: 'LIST_CREDIT_ITEMS',
  CLUB_SECTIONS_MENU_SECTIONID_OR_UNIQ_ID: 'MAINMENU',
  UNION_SECTIONS_MENU_SECTIONID_OR_UNIQ_ID: 'MAINMENU',
  DEBUG_AD_COOKIE_KEY: 'debug:ad',
  DEBUG_AD_INIT_COOKIE_KEY: 'debug:ad:init',
  // LISTINGS
  HOMEPAGE_FEATURED_ARTICLES_LISTING_ID: 'homepage-featured-articles',
  FUTBALNET_HOMEPAGE_FEATURED_ARTICLES_LISTING_ID:
    'futbalnet-homepage-featured-articles',
  SIDEBAR_PREMIUM_ARTICLES_LISTING_ID: 'sidebar-premium-articles',
  SIDEBAR_EVENT_ARTICLES_LISTING_ID: 'sidebar-event-articles',
  FUTBALNET_HOMEPAGE_TOP_ARTICLES_LAST_HOURS: 168,
  // SIDEBAR_EVENT_SECTION_ID: 6370,
  // SIDEBAR_EVENT_LIMIT: 5,
  // ARTICLE LISTS
  LIST_TOP_ARTICLES: 'TOP_ARTICLES',
  LIST_TOP_ARTICLES_LIMIT: 7,
  LIST_NEWEST_ARTICLES: 'NEWEST_ARTICLES',
  LIST_NEWEST_ARTICLES_LIMIT: 5,
  LIST_PREMIUM_ARTICLES: 'PREMIUM_ARTICLES',
  LIST_PREMIUM_ARTICLES_LIMIT: 5,
  LIST_EVENT_ARTICLES: 'LIST_EVENT_ARTICLES',
  LIST_EVENT_ARTICLES_LIMIT: 10,
  LIST_SIMILAR_ARTICLES: 'SIMILAR_ARTICLES',
  LIST_SIMILAR_ARTICLES_LIMIT: 3,
  LIST_ARTICLE_TABS_ARTICLES: 'ARTICLE_TABS_ARTICLES',
  LIST_SECTION_ARTICLES: 'SECTION_ARTICLES',
  LIST_SECTION_ARTICLES_LIMIT: 10,
  LIST_TOPIC_SECTION_ARTICLES_LIMIT: 3,
  LIST_SEARCH_RESULTS: 'SEARCH_RESULTS',
  LIST_SEARCH_RESULTS_LIMIT: 3,
  LIST_AUTHOR_ARTICLES: 'AUTHOR_ARTICLES',
  LIST_AUTHOR_ARTICLES_LIMIT: 3,
  LIST_NOTFOUND_ARTICLES: 'NOTFOUND_ARTICLES',
  LIST_NOTFOUND_ARTICLES_LIMIT: 12,
  LIST_CLUB_ARTICLES: 'CLUB_ARTICLES',
  LIST_CLUB_ARTICLES_LIMIT: 5,
  LIST_UNION_ARTICLES: 'UNION_ARTICLES',
  LIST_UNION_ARTICLES_LIMIT: 5,
  LIST_UNION_ARTICLES_ARCHIVE: 'LIST_UNION_ARTICLES_ARCHIVE',
  LIST_UNION_ARTICLES_ARCHIVE_LIMIT: 10,
  LIST_FUTBALNET_ARTICLES_BY_SPORTNET: 'LIST_FUTBALNET_ARTICLES_BY_SPORTNET',
  LIST_FUTBALNET_ARTICLES_BY_SPORTNET_LIMIT: 4,
  LIST_FUTBALNET_BULLETIN_ARTICLES: 'LIST_FUTBALNET_BULLETIN_ARTICLES',
  LIST_FUTBALNET_BULLETIN_ARTICLES_LIMIT: 6,
  LIST_COMPETITION_ARTICLES: 'COMPETITION_ARTICLES',
  LIST_COMPETITION_ARTICLES_LIMIT: 5,
  LIST_MATCH_ARTICLES: 'MATCH_ARTICLES',
  LIST_MATCH_ARTICLES_LIMIT: 5,
  LIST_FEATURED_ARTICLES: 'FEATURED_ARTICLES',
  LIST_FUTBALNET_HOMEPAGE_ARTICLES: 'FUTBALNET_HOMEPAGE_ARTICLES',
  LIST_TRANSFERS_ARTICLES: 'LIST_TRANSFERS_ARTICLES',
  LIST_FEATURED_VIDEO_ARTICLES: 'FEATURED_VIDEO_ARTICLES',
  LIST_NEWS_FEED_ARTICLES: 'NEWS_FEED_ARTICLES',
  LIST_NEWS_FEED_ARTICLES_LIMIT: 10,
  LIST_COMITEES: 'LIST_COMITEES',
  LIST_SEARCH_MEMBERS: 'LIST_SEARCH_MEMBERS',
  LIST_SEARCH_MEMBERS_LIMIT: 50,
  LIST_COMMITEES_WITH_LATEST_RESOLUTIONS:
    'LIST_COMMITEES_WITH_LATEST_RESOLUTIONS',
  LIST_SMARTTAG_ARTICLES: 'LIST_SMARTTAG_ARTICLES',
  LIST_SMARTTAG_ARTICLES_LIMIT: 10,
  LIST_SMARTTAGS_BY_SERIALIZED_KEY_VALUE:
    'LIST_SMARTTAGS_BY_SERIALIZED_KEY_VALUE',
  // pocet otvorenych collapsible listov sutazi s aktualnymi zapasmi
  // na stranke zvazu.
  NUMBER_OF_OPENED_COMPETITIONS_LISTS: 3,
  HOMEPAGE_URL,
  LIST_REDAKCIA: 'LIST_REDAKCIA',
  LIST_REDAKCIA_DETAIL: 'LIST_REDAKCIA_DETAIL',
  // DOXXBET
  LIST_DOXXBET_SPORTNET_TOP_EVENT_LIST: 'LIST_DOXXBET_SPORTNET_TOP_EVENT_LIST',
  LIST_DOXXBET_OFFER_LIST: 'LIST_DOXXBET_OFFER_LIST',
  LIST_DOXXBET_RESULTS_LIST: 'LIST_DOXXBET_RESULTS_LIST',
  // REMP - skupiny z CRM pri ktorych sa user povazuje za usera s predplatnym
  REMP_SUBSCRIPTION_GROUPS: [
    'vsetci-predplatitelia',
    'sportnet-predplatitelia',
    'vip',
  ],
  // skupina bez reklamy
  REMP_NO_ADVERT_GROUPS: ['remp:subscription:no_advert', 'vip'],
  LIST_TV_PROGRAM_EVENTS: 'LIST_TV_PROGRAM_EVENTS',
  LIST_TV_PROGRAM_PROVIDERS: 'LIST_TV_PROGRAM_PROVIDERS',
  LIST_TV_PROGRAM_PROVIDER_DETAIL: 'LIST_TV_PROGRAM_PROVIDER_DETAIL',
  LIST_TV_PROGRAM_SPORTS: 'LIST_TV_PROGRAM_SPORTS',
  LIST_TV_PROGRAM_SPORT_DETAIL: 'LIST_TV_PROGRAM_SPORT_DETAIL',
  TOKEN_COOKIE_KEY: '_accessToken',
  ROOT_BREADCRUMB: {
    label: 'Domov',
    url: HOMEPAGE_URL,
  },
  UNION_BULLETIN_ARTICLE_DOCTYPE_IDENT: 'uradnasprava',
  UNION_ARTICLE_DOCTYPE_IDENT: 'article',
  SUBSCRIPTION_TEXT: 'Predplatiť',
  /**
   * Text pre odklik na prihlásenie.
   */
  LOGIN_TEXT: 'Prihlásenie',
  nike: NIKE,
  FEEDS_GOSSIP_APP_SPACE: process.env.REACT_APP_APPSPACE!,
  FEEDS_GOSSIP_APP: 'feeds',
  FEEDS_GOSSIP_ID: 'klebety-z-futbalnetu',
  FEEDS_GOSSIP_LIST_DEFAULT_LIMIT: 20
} as const;

export enum DoxxbetSectionTabs {
  ALL = '#vsetky',
  PAST = '#dohrane',
  FUTURE = '#budesahrat',
  LIVE = '?live',
}

/* TODO: oba udaje by mali chodit v `custom_data` */
export const IIHF_MS = {
  '2016': '607e97590af455d0e08aa19a',
  '2017': '607e976e0af455d0e08ab09c',
  '2018': '607e977c0af455d0e08abd9a',
  '2019': '607e978b0af455d0e08acaf8',
  '2021': '608bfa1e1017eb8421a8bc41',
  '': '624c8e47d70da9f0a5efdea9',
};

export const IIHF_MS_ACTUAL_YEAR = '2022';

export const IIHF_ZOH = {
  '': '61f78f49383ee3de7110fc78',
};

export const IIHF_ZOH_ACTUAL_YEAR = '2022';

export const UEFA_ME = {
  '': '5ea2d4420bab94105c6c52ce',
};

export const UEFA_ME_ACTUAL_YEAR = '2020';

export const AMAURY_TDF = {
  '': '629f5497b4bad4e7b2570316',
  '2021': '60c9dd783ca5700012f983f3',
};

export const AMAURY_TDF_ACTUAL_YEAR = '2022';

export const COMPETITION_PANEL_SETTINGS: {
  MATCHES_PANEL_ROOT_SECTION?: string;
  MATCHES_PANEL_COMPETITION?: string;
  MATCHES_PANEL_IMAGE?: string;
} = {
  // MATCHES_PANEL_ROOT_SECTION: 'oh-2024-pariz',
  // MATCHES_PANEL_COMPETITION:
  //   'international-olympic-committee:6698d6364ee63170003ede55',
  // MATCHES_PANEL_IMAGE: '',
};
