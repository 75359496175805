import { __, isBrowser } from '@sportnet/ui/lib/utilities';
import { RouteComponentProps } from 'react-router';
import { getCanonicalUrl } from '../../Transfers/utilities';
import AdManager from '../../../components/Ads/AdManager';
import { Helmet } from 'react-helmet-async';
import RempIntegration from '../../../containers/RempIntegration';
import FutbalnetHomeHeader from '../../FutbalnetHome/FutbalnetHomeHeader';
import TopAd from '../../../components/Ads/TopAd';
import ListBodyRespAd from '../../../components/Ads/ListBodyRespAd';
import { rem } from 'polished';
import {
  BottomPaddedSidebarLayout,
  Sidebar,
  SidebarLayoutContent,
  StickySidebarContainer,
} from '../../../components/Layout/SidebarLayout';
import MegaboardAd from '../../../components/Ads/MegaboardAd';
import { compose } from 'redux';
import { connect } from 'react-redux';
import React from 'react';
import styled, { useTheme } from 'styled-components';
import MaxWidthBox from '../../../components/MaxWidthBox';
import BigAd from '../../../components/Ads/BigAd';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { RootState } from '../../../configureStore';
import { authUserIsNoAdvertsSelector } from '../../App/selectors';
import { appSettingsSelector } from '../../DomainResolver/selectors';
import { fetchQ, useQ } from '../../../query';
import { getFeedItemById } from './features';
import config from '../../../config';
import GossipLoader from '../GossipLoader';
import NoResults from '../../../components/v2/NoResults';
import { useAsyncData } from 'ssr-service';
import { useQueryClient } from '@tanstack/react-query';
import RedirectError from '../../../RedirectError';
import {
  getFeedItemDetailUrl,
  getFeedItemItemId,
  getFeedItemSlug,
  getGossipsBaseUrl,
} from '../utils';
import NotFoundError from '../../../NotFoundError';
import GossipDetail from './GossipDetail';
import FeedsTracker from '../../../containers/FeedsTracker';
import useTopArticlesSidebar from '../../../hooks/useTopArticlesSidebar';
import useInfiniteListParams from '../../../hooks/useInfiniteListParams';
import TopArticlesSidebar from '../../../containers/TopArticlesSidebar';
import SideAd from '../../../components/Ads/SideAd';
import ResponsiveTopArticlesSidebar from '../../Home/ResponsiveTopArticlesSidebar';
import SectionBodyAd from '../../../components/Sections/Ads/SectionBodyAd';
import InfiniteListingArticleList from '../../../containers/InfiniteListingArticleList';
import SectionBlockHeaderContainer from '../../../components/Sections/SectionBlockHeader/SectionBlockHeaderContainer';
import SectionNameSmall from '../../../components/Sections/SectionBlockHeader/SectionNameSmall';
import { ITheme } from '../../../theme/theme';
import Icon from '../../../components/Icon';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { ROOT_BREADCRUMB } from '../../../containers/SectionsBreadcrumbs';
import SDGossipNewsArticle from '../../../containers/StructuredData/SDGossipNewsArticle';
import { getAuthorTag } from '../../../utilities/adverts/getAuthorTag';

const SpacedMaxWidthBox = styled(MaxWidthBox)`
  margin-top: ${rem(24)};
  ${mb('s')} {
    margin-top: ${rem(32)};
  }
`;

const GossipContentWrapper = styled.div`
  width: 100%;
  margin-left: auto;
  margin-top: ${rem(24)};
`;

const SpacedBigAd = styled(BigAd)`
  && {
    min-height: ${rem(120)};
    margin-bottom: ${rem(24)};
    ${mb('l')} {
      margin-bottom: ${rem(32)};
    }
  }
`;

const GossipLoaderStyled = styled(GossipLoader)`
  padding-left: 0;
  padding-right: 0;
  ${mb('l')} {
    padding: 0;
  }
`;

export const StyledSectionBlockHeaderContainer = styled(
  SectionBlockHeaderContainer
)`
  margin-top: ${rem(48)};
  ${mb('s')} {
    margin-top: ${rem(56)};
  }
`;

export const StyledIcon = styled(Icon)`
  margin-right: ${rem(4)};
`;

const mapStateToProps = (state: RootState) => {
  return {
    authUserIsNoAdverts: authUserIsNoAdvertsSelector(state),
    appSettings: appSettingsSelector(state),
  };
};

type RouterProps = RouteComponentProps<
  {
    id: string;
    slug?: string;
  },
  void
>;

type Props = ReturnType<typeof mapStateToProps> & RouterProps;

const GossipDetailPage: React.FC<Props> = ({
  location: { pathname, search, hash },
  authUserIsNoAdverts,
  params: { id, slug },
  router,
}) => {
  const canonicalUrl = getCanonicalUrl(pathname, search);
  const siteId = `${pathname}/${search}/${hash}`;

  const theme = useTheme() as ITheme;

  const [page, setPage] = React.useState(1);
  const { initialLimit, initialOffset, nextOffset } =
    useInfiniteListParams(page);

  const [activeTabTopArticles, setActiveTabTopArticles] =
    useTopArticlesSidebar();

  const queryClient = useQueryClient();

  const { data, isLoading, isRefetching } = useQ(
    getFeedItemById,
    {
      id: `${config.FEEDS_GOSSIP_APP_SPACE}:${config.FEEDS_GOSSIP_APP}:${config.FEEDS_GOSSIP_ID}:${id}`,
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  const feedItemSlug = getFeedItemSlug(data?.title ?? '');

  if (data && feedItemSlug && slug !== feedItemSlug) {
    router.replace(
      `${getGossipsBaseUrl()}/${getFeedItemItemId(data._id)}/${feedItemSlug}/`
    );
  }

  const handleLoadMoreArticles = () => {
    setPage((prev) => ++prev);
  };

  // ssr
  useAsyncData(async () => {
    if (!isBrowser()) {
      try {
        const res = await fetchQ(queryClient, getFeedItemById, {
          id: `${config.FEEDS_GOSSIP_APP_SPACE}:${config.FEEDS_GOSSIP_APP}:${config.FEEDS_GOSSIP_ID}:${id}`,
        });

        if (res.publishedState === 'unpublished') {
          if (res.url) {
            throw new RedirectError({
              location: res.url,
              permanent: true,
            });
          } else {
            throw new RedirectError({
              location: getGossipsBaseUrl({ trailingSlash: true }),
              permanent: true,
            });
          }
        }

        const feedItemSlug = getFeedItemSlug(res.title);

        if (slug !== feedItemSlug) {
          throw new RedirectError({
            location: `${getGossipsBaseUrl()}/${getFeedItemItemId(
              res._id
            )}/${feedItemSlug}/`,
            permanent: true,
          });
        }
      } catch (error: any) {
        if (error.statusCode === 404) {
          throw new NotFoundError(error);
        } else if (error.identifier === 'REDIRECT') {
          throw error;
        } else {
          throw new NotFoundError(error);
        }
      }
    }
  }, [queryClient, isBrowser, slug]);

  return (
    <>
      <AdManager
        site="rubric"
        siteId={siteId}
        origin="futbalnet"
        pagetype="other"
        rubric="futbalnet-pikosky"
        author={getAuthorTag(
          (data?.authors ?? []).map((author) => ({
            name: author.displayName,
            sportnetId: author.sportnetId,
          }))
        )}
      />
      <Helmet>
        <title>{data?.title ?? ''}</title>
        <meta
          name="description"
          content={data?.extendedDescriptionExcerpt ?? ''}
        />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:title" content={data?.title ?? ''} />
        <meta
          property="og:description"
          content={data?.extendedDescriptionExcerpt ?? ''}
        />
        <meta property="og:type" content="website" />
        {data?.ogImageUrl && (
          <meta property="og:image" content={data?.ogImageUrl} />
        )}
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      {data && <SDGossipNewsArticle gossip={data} />}
      <RempIntegration destroy />
      <FutbalnetHomeHeader />
      {data && (
        <FeedsTracker feedItemId={data?._id} appSpace={data?.appSpace} />
      )}
      <TopAd />
      <ListBodyRespAd name="list_body_1" />
      {!authUserIsNoAdverts && <SpacedBigAd name="big_1" />}
      <BottomPaddedSidebarLayout className="sptn-main-content">
        <SidebarLayoutContent>
          <GossipContentWrapper>
            {isLoading || isRefetching ? (
              <GossipLoaderStyled skeletonClassName="skeleton" />
            ) : !!data ? (
              <GossipDetail
                smarttags={data.smarttags}
                date={data.date}
                title={data.title}
                extendedDescription={data.extendedDescription}
                detailUrl={getFeedItemDetailUrl(data._id, data.title)}
                authors={data.authors}
                discussion={data.discussion}
              />
            ) : (
              <NoResults
                text={__('Klebeta sa nenašla')}
                resetText="Prejsť na zoznam klebiet"
                onResetFilterClick={() => {
                  router.push(getGossipsBaseUrl({ trailingSlash: true }));
                }}
              />
            )}
          </GossipContentWrapper>
          <SectionBodyAd />
          <StyledSectionBlockHeaderContainer
            left={
              <>
                <StyledIcon name="news" size={24} color={theme.color.primary} />
                <SectionNameSmall>
                  {__('Najnovšie články z futbalnetu')}
                </SectionNameSmall>
              </>
            }
          />
          <InfiniteListingArticleList
            initialLimit={initialLimit}
            nextOffset={nextOffset}
            initialOffset={initialOffset}
            listingId={config.FUTBALNET_HOMEPAGE_FEATURED_ARTICLES_LISTING_ID}
            onLoadMore={handleLoadMoreArticles}
          />
        </SidebarLayoutContent>
        <Sidebar>
          <TopArticlesSidebar
            activeTab={activeTabTopArticles}
            setActiveTab={setActiveTabTopArticles}
          />
          <StickySidebarContainer>
            <SideAd name="side_1" />
          </StickySidebarContainer>
        </Sidebar>
      </BottomPaddedSidebarLayout>
      <SpacedMaxWidthBox className="sptn-main-content">
        <ResponsiveTopArticlesSidebar
          activeTab={activeTabTopArticles}
          setActiveTab={setActiveTabTopArticles}
        />
      </SpacedMaxWidthBox>
      {data && (
        <Breadcrumbs
          crumbs={[
            ROOT_BREADCRUMB,
            {
              label: 'Pikošky',
              url: getGossipsBaseUrl({ trailingSlash: true }),
            },
            {
              label:
                data.title.length > 50
                  ? data.title.slice(0, 47) + '...'
                  : data.title,
              url: `${getGossipsBaseUrl()}/${getFeedItemItemId(
                data._id
              )}/${feedItemSlug}/`,
            },
          ]}
        />
      )}
      <MegaboardAd />
    </>
  );
};

export default compose(connect(mapStateToProps), React.memo)(GossipDetailPage);
