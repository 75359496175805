import { __ } from '@sportnet/content/utilities/utilities';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import format from 'date-fns/format';
import { rem } from 'polished';
import React from 'react';
import { Link } from 'react-router';
import { compose } from 'redux';
import useIsResponsiveLayout from '../../hooks/useIsResponsiveLayout';
import { IMatch } from '../../library/Competitions';
import styled, { css, withTheme } from '../../theme/styled-components';
import { ITheme } from '../../theme/theme';
import cleanRoundName from '../../utilities/cleanRoundName';
import { IAdPosition } from '../Ads/AdProvider';
import ListBodyAd from '../Ads/ListBodyAd';
import ContentNotFound from '../ContentNotFound/withAnimation';
import LoadMorePanelTable from '../LoadMorePanel/LoadMorePanelTable';
import MatchPreview from '../MatchesSlider/MatchPreview';
import ShowMoreButtonTable from '../ShowMoreButton/ShowMoreButtonTable';
import { useQ } from '../../query';
import loadNikeEventsForFutbalnet from '../../query-fns/loadNikeEventsForFutbalnet';
import { ThirdPartyEvent } from '../../library/thirdPartyResults';
import getThirdPartyEventBySportnetMatch from '../../utilities/thirdPartyResults/getThirdPartyEventBySportnetMatch';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: ${rem(12)};
  ${({ theme }) => css`
    margin: 0 -${rem(theme.grid.gutterWidth / 2)};
    width: calc(100% + ${rem(theme.grid.gutterWidth)});
  `}
  ${mb('m')} {
    margin: 0;
    width: 100%;
  }
`;

const MatchWrapper = styled.div`
  padding: ${({ theme }) => rem(theme.grid.gutterWidth / 4)};
  ${({ theme }) => rem(theme.grid.gutterWidth / 2)};
`;

const MatchesWrapper = styled.div`
  > *:nth-child(odd) {
    background: #f9f9f9;
  }
`;
const RoundNameHeader = styled.div``;

// hlavicka kola
const Header = styled.div`
  width: 100%;
  font-weight: 600;
`;

const FullWidthWrapper = styled.div`
  margin-bottom: ${rem(16)};
  > div:nth-child(odd) {
    background: #f9f9f9;
  }
  > div:last-child {
    border-radius: 0 0 ${rem(8)} ${rem(8)};
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.collapsibleList.headerBg};
  color: ${({ theme }) => theme.textColor};
  padding: ${rem(12)} ${rem(16)};
  margin-bottom: ${rem(2)};
  margin-top: ${rem(2)};
`;

const HeaderTableLink = styled(Link)`
  line-height: ${rem(14.4)};
  text-decoration: underline;
`;

interface OwnProps {
  matches: IMatch[];
  onLoadMore?: () => void;
  forceFullWidth?: boolean;
  omitRounds?: boolean;
  omitCompetitionName?: boolean;
  title?: string;
  showCompetitionName?: boolean;
  isFetching: boolean;
  appSpace?: string;
  ad?: IAdPosition;
  collapsible?: boolean;
  tableLink?: string;
  showMoreLink?: string;
  thirdPartyResultsType?: ThirdPartyEvent['type'];
}

type Props = { theme: ITheme } & OwnProps;

const MatchesList: React.FC<Props> = ({
  theme,
  matches,
  onLoadMore,
  isFetching,
  forceFullWidth,
  omitRounds,
  omitCompetitionName,
  title,
  showCompetitionName,
  ad,
  tableLink,
  showMoreLink,
}) => {
  const { data: thirdPartyResultsData } = useQ(
    loadNikeEventsForFutbalnet,
    { segment: 'all' },
    { enabled: true },
  );

  const mobileLayout = useIsResponsiveLayout();

  const matchesByRound = matches.reduce(
    (
      acc: {
        [key: string]: {
          matches: IMatch[];
          competitionName: string;
          competitionPartName: string;
          roundName: string;
        };
      },
      m,
    ) => {
      if (m.round) {
        const key = `${format(
          m.round.dateFrom && m.startDate
            ? new Date(m.round.dateFrom)
            : new Date(),
          'yyyy-MM-dd',
        )}|${m.competitionPart._id}|${m.round.name}`;

        if (key in acc) {
          acc[key].matches.push(m);
        } else {
          acc[key] = {
            competitionName: m.competition?.name ?? '',
            competitionPartName: m.competitionPart?.name ?? '',
            roundName: m.round.name ?? '',
            matches: [m],
          };
        }
      }
      return acc;
    },
    {},
  );

  const renderRoundsList = () =>
    Object.keys(matchesByRound).map((roundId, roundIdx) => {
      const { competitionName, competitionPartName, roundName, matches } =
        matchesByRound[roundId];
      const headerTitle: string[] = omitCompetitionName
        ? []
        : competitionName === competitionPartName
        ? [competitionPartName]
        : [competitionName, competitionPartName];

      if (!omitRounds) {
        headerTitle.push(cleanRoundName(roundName));
      } else {
        if (roundIdx === 0 && !!title) {
          headerTitle.push(title);
        }
      }

      const anyHasStream = matches.find(
        (m) =>
          (m.internet_broadcasters || []).length ||
          (m.television_broadcasters || []).length,
      );
      return (
        <RoundNameHeader key={roundId}>
          <HeaderWrapper>
            <Header>{omitRounds ? '' : headerTitle.join(' - ')}</Header>
            {tableLink && (
              <HeaderTableLink to={tableLink || ''}>
                {__('Tabuľka')}
              </HeaderTableLink>
            )}
          </HeaderWrapper>
          <MatchesWrapper>
            {matches.map((i) => {
              return (
                <MatchWrapper key={i._id}>
                  <MatchPreview
                    view={
                      !mobileLayout && !forceFullWidth ? 'stretched' : 'compact'
                    }
                    showCompetitionName={!!showCompetitionName}
                    theme={theme}
                    match={i}
                    thirdPartyEvent={getThirdPartyEventBySportnetMatch(
                      i,
                      thirdPartyResultsData,
                    )}
                    withThirdPartyEvent
                    withStreams={anyHasStream}
                  />
                </MatchWrapper>
              );
            })}
          </MatchesWrapper>

          {!!ad && roundIdx === 0 && <ListBodyAd name={ad} />}
        </RoundNameHeader>
      );
    });

  const renderMatchesList = () => {
    const anyHasDoxx = matches.find((m) => {
      return getThirdPartyEventBySportnetMatch(m, thirdPartyResultsData);
    });
    const anyHasStream = matches.find(
      (m) =>
        (m.internet_broadcasters || []).length ||
        (m.television_broadcasters || []).length,
    );
    return matches.map((match) => {
      return (
        <div key={match._id}>
          <MatchesWrapper>
            <MatchWrapper key={match._id}>
              <MatchPreview
                view={
                  !mobileLayout && !forceFullWidth ? 'stretched' : 'compact'
                }
                showCompetitionName={!!showCompetitionName}
                theme={theme}
                match={match}
                thirdPartyEvent={getThirdPartyEventBySportnetMatch(
                  match,
                  thirdPartyResultsData,
                )}
                withThirdPartyEvent={anyHasDoxx}
                withStreams={anyHasStream}
              />
            </MatchWrapper>
          </MatchesWrapper>
        </div>
      );
    });
  };

  return (
    <Wrapper>
      {typeof isFetching === 'boolean' &&
        !isFetching &&
        matches.length === 0 && (
          <ContentNotFound title={__('Nenašli sa žiadne zápasy')} />
        )}
      {forceFullWidth || Object.keys(matchesByRound).length === 0 ? (
        <FullWidthWrapper>{renderMatchesList()}</FullWidthWrapper>
      ) : (
        renderRoundsList()
      )}
      {!!onLoadMore && (
        <LoadMorePanelTable
          onLoadMore={() => !isFetching && onLoadMore()}
          loading={isFetching}
        />
      )}
      {showMoreLink && (
        <ShowMoreButtonTable
          to={showMoreLink || ''}
          text={__('Zobraziť viac')}
        />
      )}
    </Wrapper>
  );
};

export default compose(withTheme)(MatchesList);
