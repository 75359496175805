import {
  LiveBlogPostingProps,
  LiveBlogPostingUpdateProps,
} from '../../library/LiveBlogPostingProps';
import { FeedItemWithCustomSmarttags } from '../../pages/Gossips/features/getFeedItemsWithBanners';
import {
  getFeedItemDetailUrl,
  getGossipsBaseUrl,
} from '../../pages/Gossips/utils';
import getBaseUrl from '../../utilities/getBaseUrl';
import { Helmet } from 'react-helmet-async';
import React from 'react';
import UrlContext, { IUrlContext } from '../../contexts/UrlContext';

const transformGossipToLiveBlogUpdate = (
  gossip: FeedItemWithCustomSmarttags,
  getAuthorUrlFn: IUrlContext['getAuthorUrl']
): LiveBlogPostingUpdateProps => {
  const keywords = gossip.smarttags.map((st) => st.label).join(', ');
  const author =
    Array.isArray(gossip.authors) && gossip.authors.length > 0
      ? gossip.authors[0]
      : undefined;
  const authorName = author?.displayName ?? '';
  const isAuthorOrganisation = authorName.split(' ').length > 1 ? false : true;
  const authorUrl = author?.sportnetId
    ? `${getBaseUrl()}${getAuthorUrlFn({
        name: author.displayName,
        sportnetId: author.sportnetId,
      })}`
    : '';
  const articleBody = gossip.extendedDescriptionExcerpt ?? '';
  return {
    '@type': 'BlogPosting',
    ...(author
      ? {
          author: {
            '@type': isAuthorOrganisation ? 'Organization' : 'Person',
            name: authorName,
            ...(authorUrl ? { url: authorUrl } : {}),
          },
        }
      : {}),
    headline: gossip.title,
    ...(articleBody
      ? {
          articleBody: gossip.extendedDescriptionExcerpt ?? '',
        }
      : {}),
    datePublished: new Date(gossip.date).toISOString(),
    url: getFeedItemDetailUrl(gossip._id, gossip.title),
    publisher: {
      '@type': 'Organization',
      name: 'Sportnet.sk',
      logo: {
        '@type': 'ImageObject',
        url: 'https://sportnet.sme.sk/sportnet-publisher-logo.png',
      },
    },
    ...(keywords ? { keywords } : {}),
  };
};

interface OwnProps {
  gossips: FeedItemWithCustomSmarttags[];
  // datum najnovsej musi poslat nadradeny komponent ako prop
  newestGossipDate: Date | null;
}

const SDGossipsLiveBlogPosting: React.FC<OwnProps> = ({
  gossips,
  newestGossipDate,
}) => {
  const { getAuthorUrl } = React.useContext(UrlContext);
  if (gossips.length > 0) {
    const structuredData: LiveBlogPostingProps & {
      '@context': 'https://schema.org';
    } = {
      '@context': 'https://schema.org',
      '@type': 'LiveBlogPosting',
      mainEntityOfPage: `${getBaseUrl()}/futblanet/`,
      headline: 'Futbalové pikošky',
      url: `${getBaseUrl()}${getGossipsBaseUrl()}`,
      datePublished: '2024-08-01',
      ...(newestGossipDate
        ? { dateModified: newestGossipDate.toISOString() }
        : {}),
      liveBlogUpdate: gossips.map((gossip) =>
        transformGossipToLiveBlogUpdate(gossip, getAuthorUrl)
      ),
    };
    return (
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(structuredData, null, 2)}
        </script>
      </Helmet>
    );
  }

  return null;
};

export default SDGossipsLiveBlogPosting;
