/* tslint:disable */
/* eslint:disable */
import 'isomorphic-fetch';

export type ThenArg<T> = T extends Promise<infer U>
  ? U
  : T extends (...args: any[]) => Promise<infer U>
  ? U
  : T;

type QueryParameters = {
  [key: string]:
    | string
    | number
    | string[]
    | number[]
    | boolean
    | boolean[]
    | undefined;
};

export type PublicSmartTagResponse = {
  _id: string;

  appId: Array<string>;

  appSpace: string;

  identifier: string;

  key: string;

  value: string;

  meta?: {
    title?: string;

    description?: string;
  };

  url?: string;
};
export type SmartTagResponse = {
  _id: string;

  appId: Array<string>;

  appSpace: string;

  identifier: string;

  key: string;

  value: string;

  meta?: {
    title?: string;

    description?: string;
  };

  url?: string;

  content?: Array<{}>;
};
export type SmartTagInsert = {
  key: string;

  values: Array<{
    key: string;
  }>;
};
export type ValuesList = {
  values: Array<{
    key: string;

    title?: string;
  }>;
};
export type SmarttagsList = {
  smarttags: Array<{
    _id: string;

    appId: Array<string>;

    appSpace: string;

    identifier: string;

    key: string;

    value: string;

    meta?: {
      title?: string;

      description?: string;
    };

    url?: string;

    content?: Array<{}>;
  }>;
};
export type PublicSmarttagsList = {
  smarttags: Array<{
    _id: string;

    appId: Array<string>;

    appSpace: string;

    identifier: string;

    key: string;

    value: string;

    meta?: {
      title?: string;

      description?: string;
    };

    url?: string;
  }>;
};
export type SmarttagValue = {
  key: string;

  title?: string;
};
export type SmarttagMetadata = {
  title?: string;

  description?: string;
};
export type Elementary_TagKey = string;
export type Elementary_TagValue = string;
export type TagList = Array<{
  appSpace?: string;

  key?: string;

  values?: Array<{
    _id?: string;

    key?: string;

    appId?: Array<string>;
  }>;
}>;
export type Error = {
  statusCode: number;

  name: string;

  description: string;

  payload?: {};

  userinfo?: string;
};

class ApiError {
  public message: string;
  public details: Error | null = null;

  constructor(message: string) {
    this.message = message;
  }
}

/**
 *
 * @class TagmanagerApi
 * @param {(string)} [domainOrOptions] - The project domain.
 */
class TagmanagerApi {
  protected baseUrl: string = '';
  protected headers: { name: string; value: string }[] = [];

  serializeQueryParams(parameters: QueryParameters) {
    return Object.keys(parameters)
      .reduce((acc: string[], p) => {
        const param = parameters[p];
        if (typeof param === 'undefined' || param === '') {
          return acc;
        }
        return [
          ...acc,
          `${encodeURIComponent(p)}=${encodeURIComponent(
            String(parameters[p]),
          )}`,
        ];
      }, [])
      .join('&');
  }

  protected transformParameter(value: any, transformOperation?: string) {
    switch (transformOperation) {
      case 'joinUsingPipes':
        return Array.isArray(value) ? value.join('|') : value;
      default:
        return value;
    }
  }

  public setBaseUrl(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  public setHeaders(headers: { name: string; value: string }[]) {
    this.headers = headers;
  }

  protected appendAuthHeaders(headerParams: Headers) {
    const headers = new Headers(headerParams);
    return headers;
  }

  private async request(
    method: string,
    url: string,
    body: any,
    headers: Headers = new Headers(),
    queryParameters: QueryParameters = {},
  ) {
    const queryParams =
      queryParameters && Object.keys(queryParameters).length
        ? this.serializeQueryParams(queryParameters)
        : null;
    const urlWithParams = url + (queryParams ? '?' + queryParams : '');

    if (headers.get('Content-Type') === 'multipart/form-data') {
      const form = new FormData();
      for (let k in body) {
        form.append(k, body[k]);
      }
      body = form;
    } else if (
      headers.get('Content-Type') === 'application/json' &&
      body &&
      Object.keys(body).length > 0
    ) {
      body = JSON.stringify(body);
    } else {
      body = undefined;
    }

    if (headers.get('Content-Type') === 'multipart/form-data') {
      headers.delete('Content-Type');
    }

    if (this.headers.length > 0) {
      this.headers.forEach((h) => {
        headers.append(h.name, h.value);
      });
    }

    const response = await fetch(urlWithParams, { method, headers, body });
    if (response.ok) {
      const responseContentType =
        (response.headers && response.headers.get('Content-Type')) || '';
      if (responseContentType.includes('application/json')) {
        return response.json();
      } else if (responseContentType.includes('application/pdf')) {
        return response.blob();
      } else if (responseContentType.includes('text/plain')) {
        return response.text();
      }
      return {};
    } else {
      const err = new ApiError(response.statusText);
      err.details = await response.json();
      throw err;
    }
  }

  /**
    * Get values of smarttag by appSpace and key, If q is not set then max 1000 values can be returned

    * @method
    * @name TagmanagerApi#publicGetSmarttagValues
    */
  publicGetSmarttagValues(
    appSpace: string,
    key: string,
    parameters: {
      q?: string;
    } = {},
  ): Promise<{
    values: Array<{
      key: string;

      title?: string;
    }>;
  }> {
    let path = '/public/{appSpace}/smarttags/{key}/values';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{key}', key.toString());

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * Get detail of smarttag by appSpace key and value

    * @method
    * @name TagmanagerApi#publicGetSmarttag
    */
  publicGetSmarttag(
    appSpace: string,
    key: string,
    value: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    appId: Array<string>;

    appSpace: string;

    identifier: string;

    key: string;

    value: string;

    meta?: {
      title?: string;

      description?: string;
    };

    url?: string;

    content?: Array<{}>;
  }> {
    let path = '/public/{appSpace}/smarttag/{key}/values/{value}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{key}', key.toString());

    path = path.replace('{value}', value.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
    * Get detail of smarttag by appSpace key and value

    * @method
    * @name TagmanagerApi#publicGetSmarttagKeyValue
    */
  publicGetSmarttagKeyValue(
    appSpace: string,
    key: string,
    value: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    appId: Array<string>;

    appSpace: string;

    identifier: string;

    key: string;

    value: string;

    meta?: {
      title?: string;

      description?: string;
    };

    url?: string;

    content?: Array<{}>;
  }> {
    let path = '/public/{appSpace}/smarttag/{key}:{value}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{key}', key.toString());

    path = path.replace('{value}', value.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Get detail of smarttag for PPO defined by unique identifier
   * @method
   * @name TagmanagerApi#publicGetSmarttagById
   */
  publicGetSmarttagById(
    appSpace: string,
    keyId: string,
    valueId: string,
    parameters: {} = {},
  ): Promise<{
    _id: string;

    appId: Array<string>;

    appSpace: string;

    identifier: string;

    key: string;

    value: string;

    meta?: {
      title?: string;

      description?: string;
    };

    url?: string;

    content?: Array<{}>;
  }> {
    let path = '/public/{appSpace}/smarttagId/{keyId}/{valueId}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{keyId}', keyId.toString());

    path = path.replace('{valueId}', valueId.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
  /**
   * Search keys and values by one query
   * @method
   * @name TagmanagerApi#publicGetSmarttags
   */
  publicGetSmarttags(
    appSpace: string,
    parameters: {
      q?: string;
      smarttags?: Array<string>;
    } = {},
  ): Promise<{
    values: Array<{
      _id: string;

      appId: Array<string>;

      appSpace: string;

      identifier: string;

      key: string;

      value: string;

      meta?: {
        title?: string;

        description?: string;
      };

      url?: string;
    }>;
  }> {
    let path = '/public/{appSpace}/smarttag';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');

    path = path.replace('{appSpace}', appSpace.toString());

    if (typeof parameters['q'] !== 'undefined') {
      queryParameters['q'] = parameters['q'];
    }

    queryParameters['q'] = this.transformParameter(queryParameters['q']);

    if (typeof parameters['smarttags'] !== 'undefined') {
      queryParameters['smarttags'] = parameters['smarttags'];
    }

    queryParameters['smarttags'] = this.transformParameter(
      queryParameters['smarttags'],
    );

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
}

export default new TagmanagerApi();
export { TagmanagerApi };
