import {
  IWidgetAdvert,
  IWidgetArticleList,
  IWidgetCompetitionDraw,
  IWidgetCompetitionStanding,
  IWidgetExternalArticle,
  IWidgetMatchesList,
  IWidgetMatchOverview,
  IWidgetPhoto,
  IWidgetRaceMatchesList,
  IWidgetSquad,
  IWidgetVideo,
} from '@sportnet/content/utilities/library';
import React from 'react';
import renderLink from '../renderLink';
import Advert from './Advert';
import ArticleList from './ArticleList';
import WidgetCompetitionDraw from './CompetitionDraw';
import CompetitionStanding from './CompetitionStanding';
import ExternalArticle from './ExternalArticle';
import MatchesList from './MatchesList';
import Photo from './Photo';
import RaceMatchesList from './RaceMatchesList';
import ResultsTable from './ResultsTable';
import { IWidgetResultsTable } from './ResultsTable/types';
import Squad from './Squad';
import Video from './Video';
import Voting from './Voting';
import MatchOverview from './MatchOverview';

export default {
  privateContentBoundary: () => null,
  photo: (widget: IWidgetPhoto) => <Photo data={widget} />,
  video: (widget: IWidgetVideo) => <Video data={widget} />,
  externalArticle: (widget: IWidgetExternalArticle) => (
    <ExternalArticle data={widget} />
  ),
  articlelist: (widget: IWidgetArticleList) => (
    <ArticleList data={widget} renderLink={renderLink} />
  ),
  advert: (widget: IWidgetAdvert) => <Advert data={widget} />,
  matchesList: (widget: IWidgetMatchesList) => <MatchesList data={widget} />,
  matchOverview: (widget: IWidgetMatchOverview) => (
    <MatchOverview data={widget} />
  ),
  raceMatchesList: (widget: IWidgetRaceMatchesList) => (
    <RaceMatchesList data={widget} />
  ),
  competitionStanding: (widget: IWidgetCompetitionStanding) => (
    <CompetitionStanding data={widget} />
  ),
  resultsTable: (widget: IWidgetResultsTable) => <ResultsTable data={widget} />,
  squad: (widget: IWidgetSquad & { competitionId?: string }) => (
    <Squad data={widget} />
  ),
  voting: (widget: any) => <Voting data={widget} />,
  competitionDraw: (widget: IWidgetCompetitionDraw) => (
    <WidgetCompetitionDraw data={widget} />
  ),
  teamMatchesList: (widget: IWidgetMatchesList) =>
    // <MatchesList data={widget} />
    null,
  roundMatchesList: (widget: IWidgetMatchesList) => (
    <MatchesList data={widget} />
  ),
} as const;
