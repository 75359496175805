import { getListResults } from '@sportnet/redux-list/ducks';
import { createSelector } from 'reselect';
import at from 'sportnet-utilities/lib/at';
import config from '../../config';
import { IDetailInitialState } from '../App/reducer';
import { detailTypeSelector, entitiesSelector } from '../App/selectors';

export const currentMatchIdSelector = (state: any) => state.currentMatchId;

export const getCurrentMatchIdSelector = createSelector(
  currentMatchIdSelector,
  (currentId) => currentId,
);

export const currentMatchSelector = createSelector(
  entitiesSelector,
  currentMatchIdSelector,
  (entities, id) =>
    id !== null && id in entities.matches ? entities.matches[id] : null,
);

const currentMatchDomain = createSelector(
  currentMatchIdSelector,
  detailTypeSelector('matchById'),
  (id, byId) => byId[id || ''] || ({} as Partial<IDetailInitialState['']>),
);

export const currentMatchIsFetchingSelector = createSelector(
  currentMatchDomain,
  (domain) => domain.isFetching,
);

export const currentMatchErrorSelector = createSelector(
  currentMatchDomain,
  (domain) => domain.error,
);

export const currentCompetitionPartMatchesSelector = createSelector(
  entitiesSelector,
  getListResults(config.LIST_PART_MATCHES),
  (entities, ids) => at(entities.matches, ids || []),
);

export const currentMatchH2HMatchesSelector = createSelector(
  entitiesSelector,
  getListResults(config.LIST_H2H_MATCHES),
  (entities, ids) => at(entities.matches, ids || []),
);

export const currentMatchMutualMatchesSelector = createSelector(
  entitiesSelector,
  getListResults(config.LIST_MUTUAL_MATCHES),
  (entities, ids) => at(entities.matches, ids || []),
);

export const currentMatchLastHomeTeamMatchesSelector = createSelector(
  entitiesSelector,
  getListResults(config.LIST_LAST_HOMETEAM_MATCHES),
  (entities, ids) => at(entities.matches, ids || []),
);

export const currentMatchLastAwayTeamMatchesSelector = createSelector(
  entitiesSelector,
  getListResults(config.LIST_LAST_AWAYTEAM_MATCHES),
  (entities, ids) => at(entities.matches, ids || []),
);

export const matchArticlesSelector = createSelector(
  entitiesSelector,
  getListResults(config.LIST_MATCH_ARTICLES),
  (entities, ids) => at(entities.articles, ids || []),
);
