/**
 * Len velmi jednoducho pre hokej. Malo by to ist z ciselnika podla sportu
 * ```
 * db.getCollection('settings').find({_id: 'sport_sector_phases', 'sport_sectors._id': 'ice-hockey'}, {'sport_sectors.$': true})
 * ```
 * @param phaseId
 * @returns
 */
const getPhaseLabel = (phaseId: string) => {
  switch (phaseId) {
    case '1P': {
      return '1. tretina';
    }
    case '2P': {
      return '2. tretina';
    }
    case '3P': {
      return '3. tretina';
    }
    case 'OT': {
      return 'Predĺženie';
    }
    case 'shootout': {
      return 'Sam. nájazdy';
    }
    case 'break': {
      return 'Prestávka';
    }
    case 'end': {
      return 'Koniec';
    }
    default:
      return phaseId;
  }
};

export default getPhaseLabel;
