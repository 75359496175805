import { IMatch } from '../../library/Competitions';
import { IWidgetMatchesListMatchItem } from '@sportnet/content/library/Competitions';
import { getTeamLogoSrc } from '@sportnet/content/view/widgets/MatchesList/utilities';
import { isAfter, isSameDay } from 'date-fns';
import { rem } from 'polished';
import Icon from '../../components/Icon';
import Link from '../../components/Link';
import MatchesPanelMatch from '../../sportnet.sme.sk-ui/MatchesPanel/Match';
import React from 'react';
import __ from '../../utilities/__';
import formatDate from '../../utilities/formatDate';
import getFootballEndResultType from '../../utilities/getFootballEndResultType';
import getIceHockeyEndResultType from '../../utilities/getIceHockeyEndResultType';
import getMatchAwayTeam from '../../utilities/match/getMatchAwayTeam';
import getMatchHomeTeam from '../../utilities/match/getMatchHomeTeam';
import getMatchUrl from '../../utilities/match/getMatchUrl';
import getPhaseFromTimer from '../../utilities/match/getPhaseFromTimer';
import getPhaseLabel from '../../utilities/match/getPhaseLabel';
import getPublicCountryCode from '../../utilities/match/getPublicCountryCode';
import getTeamName from '../../utilities/match/getTeamName';
import shortenRoundName from '../../utilities/match/shortenRoundName';
import styled from 'styled-components';
import { getCompetitionName } from '../../utilities/match/getCompetitionName';
import { getTeamLogoAlt } from '../../utilities/match/getTeamLogoAlt';
import { getMatchTelevisionBroadcasters } from '../../utilities/match/getMatchTelevisionBroadcasters';

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  background-color: rgba(255, 255, 255, 0.85);
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
  font-weight: 600;
  font-size: ${rem(13)};
  line-height: ${rem(15.6)};
  .icon {
    margin-left: ${rem(4)};
  }
`;

const Wrapper = styled(Link)`
  position: relative;
  height: 100%;
  display: flex;
  padding: ${rem(16)};
  font-size: ${rem(12)};
  line-height: ${rem(14.4)};
  cursor: pointer;
  outline: none;
  border: none;
  transition: background-color 250ms ease-in-out;
  align-items: center;
  &:hover {
    ${Overlay} {
      pointer-events: auto;
      opacity: 1;
    }
  }
`;

type IOwnProps = {
  match: IMatch;
  children?: React.ReactNode;
};

type IProps = IOwnProps;

const Match: React.FC<IProps> = ({ match }) => {
  const homeTeam = getMatchHomeTeam(
    match as unknown as IWidgetMatchesListMatchItem,
  );
  const awayTeam = getMatchAwayTeam(
    match as unknown as IWidgetMatchesListMatchItem,
  );
  const [homeTeamGoals, awayTeamGoals] = match.score || [];
  const [homeTeamPenaltyGoals, awayTeamPenaltyGoals] =
    match.penaltiesScore || [];

    const competitionName = getCompetitionName(match as any);

  const isClosed = !!match.closed && !!match.score && !!match.score.length;
  const isLive =
    !match.closed &&
    !!match.startDate &&
    isSameDay(new Date(), new Date(match.startDate)) &&
    isAfter(new Date(), new Date(match.startDate));
  const homeTeamName =
    getPublicCountryCode(homeTeam?.organization?.country) ||
    getTeamName(match.appSpace, homeTeam);
  const awayTeamName =
    getPublicCountryCode(awayTeam?.organization?.country) ||
    getTeamName(match.appSpace, awayTeam);

    const homeTeamNameAlt = getTeamName(match.appSpace, homeTeam);
    const awayTeamNameAlt = getTeamName(match.appSpace, awayTeam);

  const roundName = match.round?.name.startsWith('Matchday')
    ? match.competitionPart.name
    : match.round?.name;

  let PPWinnerTeamId = null;
  let SNWinnerTeamId = null;
  let PENWinnerTeamId = null;

  if (match.timer && match.timer.OT) {
    const result = getIceHockeyEndResultType(match, {
      homeTeamId: homeTeam?._id || '',
      awayTeamId: awayTeam?._id || '',
    });
    PPWinnerTeamId = result.PPWinnerTeamId;
    SNWinnerTeamId = result.SNWinnerTeamId;
  } else if (match.timer && match.timer['1ET']) {
    const result = getFootballEndResultType(match, {
      homeTeamId: homeTeam?._id || '',
      awayTeamId: awayTeam?._id || '',
    });
    PPWinnerTeamId = result.PPWinnerTeamId;
    PENWinnerTeamId = result.SNWinnerTeamId;
  }

  const homeTeamScore =
    homeTeamGoals + (homeTeamPenaltyGoals > awayTeamPenaltyGoals ? 1 : 0);

  const awayTeamScore =
    awayTeamGoals + (awayTeamPenaltyGoals > homeTeamPenaltyGoals ? 1 : 0);

  const homeTeamScoreNote =
    homeTeam?._id === PPWinnerTeamId
      ? 'PP'
      : homeTeam?._id === SNWinnerTeamId
      ? 'SN'
      : homeTeam?._id === PENWinnerTeamId
      ? 'p.k.'
      : undefined;
  const awayTeamScoreNote =
    awayTeam?._id === PPWinnerTeamId
      ? 'PP'
      : awayTeam?._id === SNWinnerTeamId
      ? 'SN'
      : awayTeam?._id === PENWinnerTeamId
      ? 'p.k.'
      : undefined;

  const homeTeamIsWinning =
    homeTeamGoals > awayTeamGoals ||
    (homeTeamGoals === awayTeamGoals &&
      homeTeamPenaltyGoals > awayTeamPenaltyGoals)
      ? true
      : false;

  const awayTeamIsWinning =
    homeTeamGoals < awayTeamGoals ||
    (homeTeamGoals === awayTeamGoals &&
      awayTeamPenaltyGoals > homeTeamPenaltyGoals)
      ? true
      : false;

  const liveMatchPhase = isLive ? getPhaseFromTimer(match.timer) : '';

  const dateOrMatchPart = isClosed
    ? formatDate(new Date(match.startDate), 'dd.MM.')
    : isLive
    ? liveMatchPhase
      ? getPhaseLabel(liveMatchPhase)
      : formatDate(new Date(match.startDate), 'dd.MM.')
    : formatDate(new Date(match.startDate), 'dd.MM. HH:mm');

  // pri odohranom zapase defualtne skracujeme nazov kola/skupiny
  // ale na mobile ide v plnej verzii
  const roundNameByMatchState = isClosed
    ? shortenRoundName(roundName ?? '')
    : roundName;

    const televisionBroadcasters = getMatchTelevisionBroadcasters(match as any);

  return (
    <Wrapper to={getMatchUrl(match)}>
      <MatchesPanelMatch
        homeTeam={{
          teamName: homeTeamName,
          teamLogoSrc: getTeamLogoSrc(match.appSpace, homeTeam as any),
          score: String(homeTeamScore),
          scoreNote: homeTeamScoreNote,
          teamLogoAlt: getTeamLogoAlt(
            match.appSpace,
            homeTeamNameAlt,
            competitionName,
          ),
        }}
        homeTeamIsWinning={homeTeamIsWinning}
        awayTeam={{
          teamName: awayTeamName,
          teamLogoSrc: getTeamLogoSrc(match.appSpace, awayTeam as any),
          score: String(awayTeamScore),
          scoreNote: awayTeamScoreNote,
          teamLogoAlt: getTeamLogoAlt(
            match.appSpace,
            awayTeamNameAlt,
            competitionName,
          ),
        }}
        awayTeamIsWinning={awayTeamIsWinning}
        isLive={isLive}
        isClosed={isClosed}
        isFuture={!isLive && !isClosed}
        dateOrMatchPhase={dateOrMatchPart}
        dateOrMatchPhaseMobile={dateOrMatchPart}
        roundName={roundNameByMatchState ?? ''}
        roundNameMobile={roundName ?? ''}
        roundNameShort={
          !isLive && !isClosed
            ? shortenRoundName(roundName ?? '')
            : roundName ?? ''
        }
        televisionBroadcasters={televisionBroadcasters}
      />
      <Overlay>
        {__('Detail zápasu')}
        <Icon name="arrowMore" size={14} />
      </Overlay>
    </Wrapper>
  );
};

export default Match;
