export interface ParsedData {
  issfSutazId: number;
  sutazID: string;
  sutazName: string;
  sutazRocnik: string;
  issfTeamId: number;
  teamId: string;
  teamDisplayName: string;
  nikeSutazId: number;
  nikeTeamID: number;
}

export const NIKE_MAP: ParsedData[] = [
  {
    issfSutazId: 4354,
    sutazID: "64799c557b634444d1b2e232",
    sutazName: "DEMIŠPORT liga - I. liga - Ženy",
    sutazRocnik: "2023/2024",
    issfTeamId: 58022,
    teamId: "64930e6b7f8d0dc994546b1a",
    teamDisplayName: "1. FC TATRAN Prešov",
    nikeSutazId: 9027,
    nikeTeamID: 41754,
  },
  {
    issfSutazId: 4354,
    sutazID: "64799c557b634444d1b2e232",
    sutazName: "DEMIŠPORT liga - I. liga - Ženy",
    sutazRocnik: "2023/2024",
    issfTeamId: 56221,
    teamId: "64822b787b634444d1439f2b",
    teamDisplayName: "AS Trenčín",
    nikeSutazId: 9027,
    nikeTeamID: 232235,
  },
  {
    issfSutazId: 4354,
    sutazID: "64799c557b634444d1b2e232",
    sutazName: "DEMIŠPORT liga - I. liga - Ženy",
    sutazRocnik: "2023/2024",
    issfTeamId: 57990,
    teamId: "64930ad07f8d0dc994506dd4",
    teamDisplayName: "FC Petržalka",
    nikeSutazId: 9027,
    nikeTeamID: 232237,
  },
  {
    issfSutazId: 4354,
    sutazID: "64799c557b634444d1b2e232",
    sutazName: "DEMIŠPORT liga - I. liga - Ženy",
    sutazRocnik: "2023/2024",
    issfTeamId: 56693,
    teamId: "6490476b7f8d0dc99486b0a9",
    teamDisplayName: "FC Spartak Trnava",
    nikeSutazId: 9027,
    nikeTeamID: 54549,
  },
  {
    issfSutazId: 4354,
    sutazID: "64799c557b634444d1b2e232",
    sutazName: "DEMIŠPORT liga - I. liga - Ženy",
    sutazRocnik: "2023/2024",
    issfTeamId: 57058,
    teamId: "64916ac37f8d0dc994a5ad34",
    teamDisplayName: "GFC regionálna akadémia",
    nikeSutazId: 9027,
    nikeTeamID: 265076,
  },
  {
    issfSutazId: 4354,
    sutazID: "64799c557b634444d1b2e232",
    sutazName: "DEMIŠPORT liga - I. liga - Ženy",
    sutazRocnik: "2023/2024",
    issfTeamId: 57131,
    teamId: "6491853f7f8d0dc994c4bbe8",
    teamDisplayName: "MFK Dukla Banská Bystrica",
    nikeSutazId: 9027,
    nikeTeamID: 6873,
  },
  {
    issfSutazId: 4354,
    sutazID: "64799c557b634444d1b2e232",
    sutazName: "DEMIŠPORT liga - I. liga - Ženy",
    sutazRocnik: "2023/2024",
    issfTeamId: 56766,
    teamId: "649049997f8d0dc994894a4a",
    teamDisplayName: "MFK Ružomberok",
    nikeSutazId: 9027,
    nikeTeamID: 39361,
  },
  {
    issfSutazId: 4354,
    sutazID: "64799c557b634444d1b2e232",
    sutazName: "DEMIŠPORT liga - I. liga - Ženy",
    sutazRocnik: "2023/2024",
    issfTeamId: 56507,
    teamId: "648afaa17b634444d1035f89",
    teamDisplayName: "MŠK Žilina",
    nikeSutazId: 9027,
    nikeTeamID: 23281,
  },
  {
    issfSutazId: 4354,
    sutazID: "64799c557b634444d1b2e232",
    sutazName: "DEMIŠPORT liga - I. liga - Ženy",
    sutazRocnik: "2023/2024",
    issfTeamId: 56271,
    teamId: "64822d737b634444d14579ed",
    teamDisplayName: "Spartak Myjava",
    nikeSutazId: 9027,
    nikeTeamID: 25760,
  },
  {
    issfSutazId: 4354,
    sutazID: "64799c557b634444d1b2e232",
    sutazName: "DEMIŠPORT liga - I. liga - Ženy",
    sutazRocnik: "2023/2024",
    issfTeamId: 57519,
    teamId: "6492d3c37f8d0dc99415b118",
    teamDisplayName: "ŠK Slovan Bratislava futbal",
    nikeSutazId: 9027,
    nikeTeamID: 25761,
  },
  {
    issfSutazId: 4347,
    sutazID: "647a3d3e7b634444d154c571",
    sutazName: "I. LSD - U19",
    sutazRocnik: "2023/2024",
    issfTeamId: 58009,
    teamId: "64930d2f7f8d0dc994530084",
    teamDisplayName: "1. FC TATRAN Prešov",
    nikeSutazId: 6013,
    nikeTeamID: 9850,
  },
  {
    issfSutazId: 4347,
    sutazID: "647a3d3e7b634444d154c571",
    sutazName: "I. LSD - U19",
    sutazRocnik: "2023/2024",
    issfTeamId: 56210,
    teamId: "64820c647b634444d123ec3b",
    teamDisplayName: "AS Trenčín",
    nikeSutazId: 6013,
    nikeTeamID: 11544,
  },
  {
    issfSutazId: 4347,
    sutazID: "647a3d3e7b634444d154c571",
    sutazName: "I. LSD - U19",
    sutazRocnik: "2023/2024",
    issfTeamId: 56313,
    teamId: "649306617f8d0dc9944ba4df",
    teamDisplayName: "FC KOŠICE",
    nikeSutazId: 6013,
    nikeTeamID: 219587,
  },
  {
    issfSutazId: 4347,
    sutazID: "647a3d3e7b634444d154c571",
    sutazName: "I. LSD - U19",
    sutazRocnik: "2023/2024",
    issfTeamId: 57976,
    teamId: "6494410b7f8d0dc9946f42ae",
    teamDisplayName: "FC Petržalka",
    nikeSutazId: 6013,
    nikeTeamID: 258089,
  },
  {
    issfSutazId: 4347,
    sutazID: "647a3d3e7b634444d154c571",
    sutazName: "I. LSD - U19",
    sutazRocnik: "2023/2024",
    issfTeamId: 56497,
    teamId: "648af9f27b634444d102b35a",
    teamDisplayName: "FC Spartak Trnava",
    nikeSutazId: 6013,
    nikeTeamID: 3540,
  },
  {
    issfSutazId: 4347,
    sutazID: "647a3d3e7b634444d154c571",
    sutazName: "I. LSD - U19",
    sutazRocnik: "2023/2024",
    issfTeamId: 58291,
    teamId: "649409be7f8d0dc994372439",
    teamDisplayName: "FC ViOn Zlaté Moravce",
    nikeSutazId: 6013,
    nikeTeamID: 292205,
  },
  {
    issfSutazId: 4347,
    sutazID: "647a3d3e7b634444d154c571",
    sutazName: "I. LSD - U19",
    sutazRocnik: "2023/2024",
    issfTeamId: 56374,
    teamId: "648ad9287b634444d1e417de",
    teamDisplayName: "FK DAC 1904 Dunajská Streda",
    nikeSutazId: 6013,
    nikeTeamID: 25428,
  },
  {
    issfSutazId: 4347,
    sutazID: "647a3d3e7b634444d154c571",
    sutazName: "I. LSD - U19",
    sutazRocnik: "2023/2024",
    issfTeamId: 56865,
    teamId: "649063417f8d0dc994a74b72",
    teamDisplayName: "FK POHRONIE Žiar nad Hronom Dolná Ždaňa",
    nikeSutazId: 6013,
    nikeTeamID: 290583,
  },
  {
    issfSutazId: 4347,
    sutazID: "647a3d3e7b634444d154c571",
    sutazName: "I. LSD - U19",
    sutazRocnik: "2023/2024",
    issfTeamId: 56386,
    teamId: "648af7297b634444d100100f",
    teamDisplayName: "FK Železiarne Podbrezová",
    nikeSutazId: 6013,
    nikeTeamID: 14218,
  },
  {
    issfSutazId: 4347,
    sutazID: "647a3d3e7b634444d154c571",
    sutazName: "I. LSD - U19",
    sutazRocnik: "2023/2024",
    issfTeamId: 57098,
    teamId: "649183fd7f8d0dc994c2b0b0",
    teamDisplayName: "MFK Dukla Banská Bystrica",
    nikeSutazId: 6013,
    nikeTeamID: 11685,
  },
  {
    issfSutazId: 4347,
    sutazID: "647a3d3e7b634444d154c571",
    sutazName: "I. LSD - U19",
    sutazRocnik: "2023/2024",
    issfTeamId: 56758,
    teamId: "649049ff7f8d0dc99489c2ae",
    teamDisplayName: "MFK Ružomberok",
    nikeSutazId: 6013,
    nikeTeamID: 12146,
  },
  {
    issfSutazId: 4347,
    sutazID: "647a3d3e7b634444d154c571",
    sutazName: "I. LSD - U19",
    sutazRocnik: "2023/2024",
    issfTeamId: 57500,
    teamId: "6492d2d17f8d0dc99414b822",
    teamDisplayName: "MFK Zemplín Michalovce",
    nikeSutazId: 6013,
    nikeTeamID: 2539,
  },
  {
    issfSutazId: 4347,
    sutazID: "647a3d3e7b634444d154c571",
    sutazName: "I. LSD - U19",
    sutazRocnik: "2023/2024",
    issfTeamId: 56487,
    teamId: "648af9427b634444d101fe2c",
    teamDisplayName: "MŠK Žilina",
    nikeSutazId: 6013,
    nikeTeamID: 11545,
  },
  {
    issfSutazId: 4347,
    sutazID: "647a3d3e7b634444d154c571",
    sutazName: "I. LSD - U19",
    sutazRocnik: "2023/2024",
    issfTeamId: 57485,
    teamId: "6492bc647f8d0dc994fdeaaf",
    teamDisplayName: "ŠK Slovan Bratislava futbal",
    nikeSutazId: 6013,
    nikeTeamID: 9849,
  },
  {
    issfSutazId: 4408,
    sutazID: "6477ac257b634444d118634a",
    sutazName: "III. liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56502,
    teamId: "649186eb7f8d0dc994c72772",
    teamDisplayName: "1.FK Svidník",
    nikeSutazId: 9152,
    nikeTeamID: 32002,
  },
  {
    issfSutazId: 4408,
    sutazID: "6477ac257b634444d118634a",
    sutazName: "III. liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 57046,
    teamId: "6492d2087f8d0dc99413eb0a",
    teamDisplayName: "AFC Nové Mesto nad Váhom",
    nikeSutazId: 9152,
    nikeTeamID: 5444,
  },
  {
    issfSutazId: 4408,
    sutazID: "6477ac257b634444d118634a",
    sutazName: "III. liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 59718,
    teamId: "649948d9eebe726b043f60a3",
    teamDisplayName: "FC - Žolík Malacky",
    nikeSutazId: 9152,
    nikeTeamID: 26061,
  },
  {
    issfSutazId: 4408,
    sutazID: "6477ac257b634444d118634a",
    sutazName: "III. liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 57579,
    teamId: "6499415ceebe726b043746a8",
    teamDisplayName: "FC Slovan Galanta",
    nikeSutazId: 9152,
    nikeTeamID: 8654,
  },
  {
    issfSutazId: 4408,
    sutazID: "6477ac257b634444d118634a",
    sutazName: "III. liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56891,
    teamId: "649065717f8d0dc994a991b0",
    teamDisplayName: "FK Beluša",
    nikeSutazId: 9152,
    nikeTeamID: 19247,
  },
  {
    issfSutazId: 4408,
    sutazID: "6477ac257b634444d118634a",
    sutazName: "III. liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 58987,
    teamId: "64962338eebe726b048825c4",
    teamDisplayName: "FK Dubnica nad Váhom",
    nikeSutazId: 9152,
    nikeTeamID: 295,
  },
  {
    issfSutazId: 4408,
    sutazID: "6477ac257b634444d118634a",
    sutazName: "III. liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56516,
    teamId: "649187207f8d0dc994c7665f",
    teamDisplayName: "FK Rača Bratislava",
    nikeSutazId: 9152,
    nikeTeamID: 5445,
  },
  {
    issfSutazId: 4408,
    sutazID: "6477ac257b634444d118634a",
    sutazName: "III. liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56970,
    teamId: "6494085d7f8d0dc99435b1ca",
    teamDisplayName: "FK Slovan Duslo Šaľa",
    nikeSutazId: 9152,
    nikeTeamID: 70438,
  },
  {
    issfSutazId: 4408,
    sutazID: "6477ac257b634444d118634a",
    sutazName: "III. liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 59041,
    teamId: "6499329deebe726b04283535",
    teamDisplayName: "FTC Fiľakovo",
    nikeSutazId: 9152,
    nikeTeamID: 19562,
  },
  {
    issfSutazId: 4408,
    sutazID: "6477ac257b634444d118634a",
    sutazName: "III. liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56283,
    teamId: "648af8cc7b634444d1018c0b",
    teamDisplayName: "Futbalový klub Podkonice",
    nikeSutazId: 9152,
    nikeTeamID: 221933,
  },
  {
    issfSutazId: 4408,
    sutazID: "6477ac257b634444d118634a",
    sutazName: "III. liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 57733,
    teamId: "649300e67f8d0dc99444fbf2",
    teamDisplayName: "MFK Snina",
    nikeSutazId: 9152,
    nikeTeamID: 6804,
  },
  {
    issfSutazId: 4408,
    sutazID: "6477ac257b634444d118634a",
    sutazName: "III. liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56129,
    teamId: "64902d6b7f8d0dc994655a35",
    teamDisplayName: "MFK Vranov nad Topľou",
    nikeSutazId: 9152,
    nikeTeamID: 1492,
  },
  {
    issfSutazId: 4408,
    sutazID: "6477ac257b634444d118634a",
    sutazName: "III. liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 57536,
    teamId: "64953ed37f8d0dc99448b1b8",
    teamDisplayName: "MFK Zvolen",
    nikeSutazId: 9152,
    nikeTeamID: 6805,
  },
  {
    issfSutazId: 4408,
    sutazID: "6477ac257b634444d118634a",
    sutazName: "III. liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 57531,
    teamId: "6492d3687f8d0dc994154d69",
    teamDisplayName: "MŠK FOMAT Martin",
    nikeSutazId: 9152,
    nikeTeamID: 1231,
  },
  {
    issfSutazId: 4408,
    sutazID: "6477ac257b634444d118634a",
    sutazName: "III. liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56578,
    teamId: "64901cdc7f8d0dc99450b9b7",
    teamDisplayName: "MŠK NOVOHRAD Lučenec",
    nikeSutazId: 9152,
    nikeTeamID: 19563,
  },
  {
    issfSutazId: 4408,
    sutazID: "6477ac257b634444d118634a",
    sutazName: "III. liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 58967,
    teamId: "649621e2eebe726b0486ced0",
    teamDisplayName: "MŠK Námestovo",
    nikeSutazId: 9152,
    nikeTeamID: 10096,
  },
  {
    issfSutazId: 4408,
    sutazID: "6477ac257b634444d118634a",
    sutazName: "III. liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56899,
    teamId: "6490650d7f8d0dc994a9307f",
    teamDisplayName: "MŠK Rimavská Sobota",
    nikeSutazId: 9152,
    nikeTeamID: 2244,
  },
  {
    issfSutazId: 4408,
    sutazID: "6477ac257b634444d118634a",
    sutazName: "III. liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 57153,
    teamId: "6494a62b7f8d0dc994cf6bbc",
    teamDisplayName: "MŠK Spišské Podhradie",
    nikeSutazId: 9152,
    nikeTeamID: 26156,
  },
  {
    issfSutazId: 4408,
    sutazID: "6477ac257b634444d118634a",
    sutazName: "III. liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56345,
    teamId: "648ad6527b634444d1e1643b",
    teamDisplayName: "MŠK Tesla Stropkov",
    nikeSutazId: 9152,
    nikeTeamID: 4505,
  },
  {
    issfSutazId: 4408,
    sutazID: "6477ac257b634444d118634a",
    sutazName: "III. liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 57975,
    teamId: "649440397f8d0dc9946e66f3",
    teamDisplayName: "OFK Baník Lehota pod Vtáčnikom",
    nikeSutazId: 9152,
    nikeTeamID: 29908,
  },
  {
    issfSutazId: 4408,
    sutazID: "6477ac257b634444d118634a",
    sutazName: "III. liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56557,
    teamId: "64902ebd7f8d0dc994675e93",
    teamDisplayName: "OK Častkovce",
    nikeSutazId: 9152,
    nikeTeamID: 19250,
  },
  {
    issfSutazId: 4408,
    sutazID: "6477ac257b634444d118634a",
    sutazName: "III. liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56183,
    teamId: "6494416c7f8d0dc9946fa6f5",
    teamDisplayName: "Partizán Bardejov BŠK",
    nikeSutazId: 9152,
    nikeTeamID: 7798,
  },
  {
    issfSutazId: 4408,
    sutazID: "6477ac257b634444d118634a",
    sutazName: "III. liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 59051,
    teamId: "6496b57beebe726b04f5ed93",
    teamDisplayName: "RSC HAMSIK ACADEMY Banská Bystrica o.z.",
    nikeSutazId: 9152,
    nikeTeamID: 288291,
  },
  {
    issfSutazId: 4408,
    sutazID: "6477ac257b634444d118634a",
    sutazName: "III. liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 59071,
    teamId: "64993196eebe726b04273e00",
    teamDisplayName: "Slávia TU Košice",
    nikeSutazId: 9152,
    nikeTeamID: 32001,
  },
  {
    issfSutazId: 4408,
    sutazID: "6477ac257b634444d118634a",
    sutazName: "III. liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 58861,
    teamId: "6496209beebe726b0485eac0",
    teamDisplayName: "Stará Ľubovňa Redfox Football Club",
    nikeSutazId: 9152,
    nikeTeamID: 26187,
  },
  {
    issfSutazId: 4408,
    sutazID: "6477ac257b634444d118634a",
    sutazName: "III. liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 58424,
    teamId: "64942af77f8d0dc99458a3e4",
    teamDisplayName: "TJ Baník Kalinovo",
    nikeSutazId: 9152,
    nikeTeamID: 8796,
  },
  {
    issfSutazId: 4408,
    sutazID: "6477ac257b634444d118634a",
    sutazName: "III. liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 59170,
    teamId: "64993334eebe726b0428c324",
    teamDisplayName: "TJ Družstevník Veľké Ludince",
    nikeSutazId: 9152,
    nikeTeamID: 19263,
  },
  {
    issfSutazId: 4408,
    sutazID: "6477ac257b634444d118634a",
    sutazName: "III. liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 59288,
    teamId: "64993647eebe726b042be063",
    teamDisplayName: "TJ Tatran Oravské Veselé",
    nikeSutazId: 9152,
    nikeTeamID: 19317,
  },
  {
    issfSutazId: 4408,
    sutazID: "6477ac257b634444d118634a",
    sutazName: "III. liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 58837,
    teamId: "64962053eebe726b0485bec0",
    teamDisplayName: "Telovýchovná jednota JEDNOTA Bánová",
    nikeSutazId: 9152,
    nikeTeamID: 5448,
  },
  {
    issfSutazId: 4408,
    sutazID: "6477ac257b634444d118634a",
    sutazName: "III. liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56404,
    teamId: "64993584eebe726b042b2164",
    teamDisplayName: "ŠK Odeva Lipany",
    nikeSutazId: 9152,
    nikeTeamID: 4507,
  },
  {
    issfSutazId: 4408,
    sutazID: "6477ac257b634444d118634a",
    sutazName: "III. liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 57214,
    teamId: "649185f07f8d0dc994c5d5f1",
    teamDisplayName: "ŠKF Sereď",
    nikeSutazId: 9152,
    nikeTeamID: 6802,
  },
  {
    issfSutazId: 4346,
    sutazID: "647904787b634444d148590a",
    sutazName: "MONACObet liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 58008,
    teamId: "649621a3eebe726b048698c8",
    teamDisplayName: "1. FC TATRAN Prešov",
    nikeSutazId: 15584,
    nikeTeamID: 303,
  },
  {
    issfSutazId: 4346,
    sutazID: "647904787b634444d148590a",
    sutazName: "MONACObet liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 58488,
    teamId: "649441867f8d0dc9946fc06b",
    teamDisplayName: "FC Petržalka",
    nikeSutazId: 15584,
    nikeTeamID: 298,
  },
  {
    issfSutazId: 4346,
    sutazID: "647904787b634444d148590a",
    sutazName: "MONACObet liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 57756,
    teamId: "6493017d7f8d0dc99445d49a",
    teamDisplayName: "FC ŠTK 1914 Šamorín",
    nikeSutazId: 15584,
    nikeTeamID: 7641,
  },
  {
    issfSutazId: 4346,
    sutazID: "647904787b634444d148590a",
    sutazName: "MONACObet liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56291,
    teamId: "64940ab07f8d0dc994380fb0",
    teamDisplayName: "FK POHRONIE Žiar nad Hronom Dolná Ždaňa",
    nikeSutazId: 15584,
    nikeTeamID: 4503,
  },
  {
    issfSutazId: 4346,
    sutazID: "647904787b634444d148590a",
    sutazName: "MONACObet liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56116,
    teamId: "6479d85a7b634444d1ecef88",
    teamDisplayName: "Futbalový klub Humenné",
    nikeSutazId: 15584,
    nikeTeamID: 297,
  },
  {
    issfSutazId: 4346,
    sutazID: "647904787b634444d148590a",
    sutazName: "MONACObet liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56556,
    teamId: "649017f17f8d0dc9944a8433",
    teamDisplayName: "Futbalový klub Spišská Nová Ves",
    nikeSutazId: 15584,
    nikeTeamID: 5449,
  },
  {
    issfSutazId: 4346,
    sutazID: "647904787b634444d148590a",
    sutazName: "MONACObet liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56109,
    teamId: "647904787b634444d14858e0",
    teamDisplayName: "KFC Komárno",
    nikeSutazId: 15584,
    nikeTeamID: 24580,
  },
  {
    issfSutazId: 4346,
    sutazID: "647904787b634444d148590a",
    sutazName: "MONACObet liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 57625,
    teamId: "649409677f8d0dc99436ddc1",
    teamDisplayName: "MFK Dolný Kubín",
    nikeSutazId: 15584,
    nikeTeamID: 4506,
  },
  {
    issfSutazId: 4346,
    sutazID: "647904787b634444d148590a",
    sutazName: "MONACObet liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56531,
    teamId: "6490135d7f8d0dc99444acd3",
    teamDisplayName: "MFK Tatran Liptovský Mikuláš",
    nikeSutazId: 15584,
    nikeTeamID: 4115,
  },
  {
    issfSutazId: 4346,
    sutazID: "647904787b634444d148590a",
    sutazName: "MONACObet liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56319,
    teamId: "64822dcd7b634444d145d104",
    teamDisplayName: "MŠK Považská Bystrica",
    nikeSutazId: 15584,
    nikeTeamID: 8797,
  },
  {
    issfSutazId: 4346,
    sutazID: "647904787b634444d148590a",
    sutazName: "MONACObet liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56306,
    teamId: "64822db27b634444d145b949",
    teamDisplayName: "MŠK Púchov",
    nikeSutazId: 15584,
    nikeTeamID: 865,
  },
  {
    issfSutazId: 4346,
    sutazID: "647904787b634444d148590a",
    sutazName: "MONACObet liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56373,
    teamId: "648ad88e7b634444d1e381dc",
    teamDisplayName: "MŠK Žilina B",
    nikeSutazId: 15584,
    nikeTeamID: 7272,
  },
  {
    issfSutazId: 4346,
    sutazID: "647904787b634444d148590a",
    sutazName: "MONACObet liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 57590,
    teamId: "64953b837f8d0dc99445a3f6",
    teamDisplayName: "OFK Malženice",
    nikeSutazId: 15584,
    nikeTeamID: 12243,
  },
  {
    issfSutazId: 4346,
    sutazID: "647904787b634444d148590a",
    sutazName: "MONACObet liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 58880,
    teamId: "64962353eebe726b048838ff",
    teamDisplayName: "SLAVOJ TREBIŠOV",
    nikeSutazId: 15584,
    nikeTeamID: 1201,
  },
  {
    issfSutazId: 4346,
    sutazID: "647904787b634444d148590a",
    sutazName: "MONACObet liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56270,
    teamId: "64822d677b634444d1456fc2",
    teamDisplayName: "Spartak Myjava",
    nikeSutazId: 15584,
    nikeTeamID: 1860,
  },
  {
    issfSutazId: 4346,
    sutazID: "647904787b634444d148590a",
    sutazName: "MONACObet liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 57534,
    teamId: "6492d3407f8d0dc994152314",
    teamDisplayName: "ŠK Slovan Bratislava futbal B",
    nikeSutazId: 15584,
    nikeTeamID: 12218,
  },
  {
    issfSutazId: 4492,
    sutazID: "647c3f337b634444d1521c15",
    sutazName: "Majstrovsto regiónu - IV.liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 59557,
    teamId: "6497dac0eebe726b04e76729",
    teamDisplayName: "TJD Príbelce",
    nikeSutazId: 24600,
    nikeTeamID: 39244,
  },
  {
    issfSutazId: 4492,
    sutazID: "647c3f337b634444d1521c15",
    sutazName: "Majstrovsto regiónu - IV.liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 59313,
    teamId: "64971f54eebe726b04528a72",
    teamDisplayName: "FK Slávia Staškov",
    nikeSutazId: 24600,
    nikeTeamID: 24587,
  },
  {
    issfSutazId: 4492,
    sutazID: "647c3f337b634444d1521c15",
    sutazName: "Majstrovsto regiónu - IV.liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56178,
    teamId: "64820c197b634444d123a0ba",
    teamDisplayName: "Futbalový klub Čadca",
    nikeSutazId: 24600,
    nikeTeamID: 3539,
  },
  {
    issfSutazId: 4492,
    sutazID: "647c3f337b634444d1521c15",
    sutazName: "Majstrovsto regiónu - IV.liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 58699,
    teamId: "6495d8afeebe726b044aa1d8",
    teamDisplayName: "MŠK Kysucké Nové Mesto",
    nikeSutazId: 24600,
    nikeTeamID: 19550,
  },
  {
    issfSutazId: 4492,
    sutazID: "647c3f337b634444d1521c15",
    sutazName: "Majstrovsto regiónu - IV.liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 57014,
    teamId: "649067ef7f8d0dc994ac5507",
    teamDisplayName: "OŠK BEŠEŇOVÁ",
    nikeSutazId: 24600,
    nikeTeamID: 29302,
  },
  {
    issfSutazId: 4492,
    sutazID: "647c3f337b634444d1521c15",
    sutazName: "Majstrovsto regiónu - IV.liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56108,
    teamId: "64849c057b634444d178eb8e",
    teamDisplayName: "OŠK Baník Stráňavy",
    nikeSutazId: 24600,
    nikeTeamID: 19302,
  },
  {
    issfSutazId: 4492,
    sutazID: "647c3f337b634444d1521c15",
    sutazName: "Majstrovsto regiónu - IV.liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 57224,
    teamId: "649183457f8d0dc994c19e2b",
    teamDisplayName: "TJ Družstevník Liptovská Štiavnica",
    nikeSutazId: 24600,
    nikeTeamID: 19546,
  },
  {
    issfSutazId: 4492,
    sutazID: "647c3f337b634444d1521c15",
    sutazName: "Majstrovsto regiónu - IV.liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 58867,
    teamId: "649620ceeebe726b04860a01",
    teamDisplayName: "TJ Sokol Medzibrod",
    nikeSutazId: 24600,
    nikeTeamID: 24596,
  },
  {
    issfSutazId: 4492,
    sutazID: "647c3f337b634444d1521c15",
    sutazName: "Majstrovsto regiónu - IV.liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56983,
    teamId: "649066327f8d0dc994aa7384",
    teamDisplayName: "TJ Sokol Zubrohlava",
    nikeSutazId: 24600,
    nikeTeamID: 39246,
  },
  {
    issfSutazId: 4492,
    sutazID: "647c3f337b634444d1521c15",
    sutazName: "Majstrovsto regiónu - IV.liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 57456,
    teamId: "649279257f8d0dc994b6f112",
    teamDisplayName: "TJ Tatran Krásno nad Kysucou",
    nikeSutazId: 24600,
    nikeTeamID: 19553,
  },
  {
    issfSutazId: 4492,
    sutazID: "647c3f337b634444d1521c15",
    sutazName: "Majstrovsto regiónu - IV.liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 59982,
    teamId: "64987a26eebe726b0487fccd",
    teamDisplayName: "ŠK Badín, občianske združenie",
    nikeSutazId: 24600,
    nikeTeamID: 52330,
  },
  {
    issfSutazId: 4492,
    sutazID: "647c3f337b634444d1521c15",
    sutazName: "Majstrovsto regiónu - IV.liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 57633,
    teamId: "649300127f8d0dc99443faa4",
    teamDisplayName: "ŠK Javorník Makov",
    nikeSutazId: 24600,
    nikeTeamID: 18122,
  },
  {
    issfSutazId: 4492,
    sutazID: "647c3f337b634444d1521c15",
    sutazName: "Majstrovsto regiónu - IV.liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 59904,
    teamId: "64985b89eebe726b046534e3",
    teamDisplayName: "ŠK Prameň Kováčová",
    nikeSutazId: 24600,
    nikeTeamID: 19304,
  },
  {
    issfSutazId: 4492,
    sutazID: "647c3f337b634444d1521c15",
    sutazName: "Majstrovsto regiónu - IV.liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 57027,
    teamId: "649066667f8d0dc994aaaad9",
    teamDisplayName: "ŠK Tvrdošín",
    nikeSutazId: 24600,
    nikeTeamID: 19551,
  },
  {
    issfSutazId: 4490,
    sutazID: "647ba0837b634444d1c5174e",
    sutazName: "Majstrovstvo regiónu IV. liga Východ",
    sutazRocnik: "2023/2024",
    issfTeamId: 56587,
    teamId: "64989dc3eebe726b04b2b06e",
    teamDisplayName: "1. MFK Kežmarok",
    nikeSutazId: 24599,
    nikeTeamID: 52341,
  },
  {
    issfSutazId: 4490,
    sutazID: "647ba0837b634444d1c5174e",
    sutazName: "Majstrovstvo regiónu IV. liga Východ",
    sutazRocnik: "2023/2024",
    issfTeamId: 58794,
    teamId: "6496706eeebe726b04bec217",
    teamDisplayName: "FC LOKOMOTÍVA KOŠICE",
    nikeSutazId: 24599,
    nikeTeamID: 872,
  },
  {
    issfSutazId: 4490,
    sutazID: "647ba0837b634444d1c5174e",
    sutazName: "Majstrovstvo regiónu IV. liga Východ",
    sutazRocnik: "2023/2024",
    issfTeamId: 59985,
    teamId: "6498711ceebe726b047cb099",
    teamDisplayName: "FC Pivovar Šariš Veľký Šariš",
    nikeSutazId: 24599,
    nikeTeamID: 26184,
  },
  {
    issfSutazId: 4490,
    sutazID: "647ba0837b634444d1c5174e",
    sutazName: "Majstrovstvo regiónu IV. liga Východ",
    sutazRocnik: "2023/2024",
    issfTeamId: 59940,
    teamId: "64986aa6eebe726b04759033",
    teamDisplayName: "FK Gerlachov",
    nikeSutazId: 24599,
    nikeTeamID: 26186,
  },
  {
    issfSutazId: 4490,
    sutazID: "647ba0837b634444d1c5174e",
    sutazName: "Majstrovstvo regiónu IV. liga Východ",
    sutazRocnik: "2023/2024",
    issfTeamId: 57690,
    teamId: "6491c2d77f8d0dc99409764e",
    teamDisplayName: "FK Kechnec",
    nikeSutazId: 24599,
    nikeTeamID: 32000,
  },
  {
    issfSutazId: 4490,
    sutazID: "647ba0837b634444d1c5174e",
    sutazName: "Majstrovstvo regiónu IV. liga Východ",
    sutazRocnik: "2023/2024",
    issfTeamId: 59469,
    teamId: "6497f167eebe726b04fab062",
    teamDisplayName: "FK Poprad",
    nikeSutazId: 24599,
    nikeTeamID: 5447,
  },
  {
    issfSutazId: 4490,
    sutazID: "647ba0837b634444d1c5174e",
    sutazName: "Majstrovstvo regiónu IV. liga Východ",
    sutazRocnik: "2023/2024",
    issfTeamId: 57226,
    teamId: "649044ea7f8d0dc99483d2a6",
    teamDisplayName: "FK Čaňa",
    nikeSutazId: 24599,
    nikeTeamID: 1202,
  },
  {
    issfSutazId: 4490,
    sutazID: "647ba0837b634444d1c5174e",
    sutazName: "Majstrovstvo regiónu IV. liga Východ",
    sutazRocnik: "2023/2024",
    issfTeamId: 59952,
    teamId: "64986cfaeebe726b047806fe",
    teamDisplayName: "Futbalový klub Sobrance -Sobranecko",
    nikeSutazId: 24599,
    nikeTeamID: 26162,
  },
  {
    issfSutazId: 4490,
    sutazID: "647ba0837b634444d1c5174e",
    sutazName: "Majstrovstvo regiónu IV. liga Východ",
    sutazRocnik: "2023/2024",
    issfTeamId: 56969,
    teamId: "649168577f8d0dc994a33af9",
    teamDisplayName: "MFK Slovan Sabinov",
    nikeSutazId: 24599,
    nikeTeamID: 219255,
  },
  {
    issfSutazId: 4490,
    sutazID: "647ba0837b634444d1c5174e",
    sutazName: "Majstrovstvo regiónu IV. liga Východ",
    sutazRocnik: "2023/2024",
    issfTeamId: 56914,
    teamId: "648ea0357f8d0dc99470b4b3",
    teamDisplayName: "MFK Spartak Medzev",
    nikeSutazId: 24599,
    nikeTeamID: 19556,
  },
  {
    issfSutazId: 4490,
    sutazID: "647ba0837b634444d1c5174e",
    sutazName: "Majstrovstvo regiónu IV. liga Východ",
    sutazRocnik: "2023/2024",
    issfTeamId: 59004,
    teamId: "64958dd2eebe726b04045312",
    teamDisplayName: "MŠK SPARTAK Medzilaborce",
    nikeSutazId: 24599,
    nikeTeamID: 26155,
  },
  {
    issfSutazId: 4490,
    sutazID: "647ba0837b634444d1c5174e",
    sutazName: "Majstrovstvo regiónu IV. liga Východ",
    sutazRocnik: "2023/2024",
    issfTeamId: 58478,
    teamId: "649440907f8d0dc9946ec03e",
    teamDisplayName: "OFK - SIM Raslavice",
    nikeSutazId: 24599,
    nikeTeamID: 19559,
  },
  {
    issfSutazId: 4490,
    sutazID: "647ba0837b634444d1c5174e",
    sutazName: "Majstrovstvo regiónu IV. liga Východ",
    sutazRocnik: "2023/2024",
    issfTeamId: 58549,
    teamId: "649741e9eebe726b04725188",
    teamDisplayName: "OŠK Pavlovce nad Uhom",
    nikeSutazId: 24599,
    nikeTeamID: 39249,
  },
  {
    issfSutazId: 4490,
    sutazID: "647ba0837b634444d1c5174e",
    sutazName: "Majstrovstvo regiónu IV. liga Východ",
    sutazRocnik: "2023/2024",
    issfTeamId: 59403,
    teamId: "6496e14eeebe726b0419a2f2",
    teamDisplayName: "OŠK Rudňany",
    nikeSutazId: 24599,
    nikeTeamID: 26163,
  },
  {
    issfSutazId: 4490,
    sutazID: "647ba0837b634444d1c5174e",
    sutazName: "Majstrovstvo regiónu IV. liga Východ",
    sutazRocnik: "2023/2024",
    issfTeamId: 60057,
    teamId: "64988898eebe726b0499efb4",
    teamDisplayName: "TJ Mladosť Kalša",
    nikeSutazId: 24599,
    nikeTeamID: 26161,
  },
  {
    issfSutazId: 4490,
    sutazID: "647ba0837b634444d1c5174e",
    sutazName: "Majstrovstvo regiónu IV. liga Východ",
    sutazRocnik: "2023/2024",
    issfTeamId: 56663,
    teamId: "649131eb7f8d0dc99467ddb4",
    teamDisplayName: "TJ Sokol Ľubotice",
    nikeSutazId: 24599,
    nikeTeamID: 7720,
  },
  {
    issfSutazId: 4527,
    sutazID: "647d80567b634444d1e00fd5",
    sutazName: "Majstrovstvá regiónu - IV. liga BFZ",
    sutazRocnik: "2023/2024",
    issfTeamId: 59123,
    teamId: "6495d26eeebe726b044471a0",
    teamDisplayName: "FC Rohožník",
    nikeSutazId: 13821,
    nikeTeamID: 19289,
  },
  {
    issfSutazId: 4527,
    sutazID: "647d80567b634444d1e00fd5",
    sutazName: "Majstrovstvá regiónu - IV. liga BFZ",
    sutazRocnik: "2023/2024",
    issfTeamId: 59263,
    teamId: "6499355aeebe726b042af16f",
    teamDisplayName: "FC Slovan Modra",
    nikeSutazId: 13821,
    nikeTeamID: 26147,
  },
  {
    issfSutazId: 4527,
    sutazID: "647d80567b634444d1e00fd5",
    sutazName: "Majstrovstvá regiónu - IV. liga BFZ",
    sutazRocnik: "2023/2024",
    issfTeamId: 57196,
    teamId: "64940ae67f8d0dc994384049",
    teamDisplayName: "FK Inter Bratislava",
    nikeSutazId: 13821,
    nikeTeamID: 870,
  },
  {
    issfSutazId: 4527,
    sutazID: "647d80567b634444d1e00fd5",
    sutazName: "Majstrovstvá regiónu - IV. liga BFZ",
    sutazRocnik: "2023/2024",
    issfTeamId: 61002,
    teamId: "649deaaeeebe726b04783735",
    teamDisplayName: "FK Slovan Ivanka pri Dunaji",
    nikeSutazId: 13821,
    nikeTeamID: 19274,
  },
  {
    issfSutazId: 4527,
    sutazID: "647d80567b634444d1e00fd5",
    sutazName: "Majstrovstvá regiónu - IV. liga BFZ",
    sutazRocnik: "2023/2024",
    issfTeamId: 60757,
    teamId: "649e6c2aeebe726b04da111e",
    teamDisplayName: "FK Slovan Most pri Bratislave",
    nikeSutazId: 13821,
    nikeTeamID: 19280,
  },
  {
    issfSutazId: 4527,
    sutazID: "647d80567b634444d1e00fd5",
    sutazName: "Majstrovstvá regiónu - IV. liga BFZ",
    sutazRocnik: "2023/2024",
    issfTeamId: 58826,
    teamId: "64953dfc7f8d0dc99447e709",
    teamDisplayName: "MFK Rusovce",
    nikeSutazId: 13821,
    nikeTeamID: 19291,
  },
  {
    issfSutazId: 4527,
    sutazID: "647d80567b634444d1e00fd5",
    sutazName: "Majstrovstvá regiónu - IV. liga BFZ",
    sutazRocnik: "2023/2024",
    issfTeamId: 59083,
    teamId: "649c4405eebe726b04df84d6",
    teamDisplayName: "MŠK Senec",
    nikeSutazId: 13821,
    nikeTeamID: 34701,
  },
  {
    issfSutazId: 4527,
    sutazID: "647d80567b634444d1e00fd5",
    sutazName: "Majstrovstvá regiónu - IV. liga BFZ",
    sutazRocnik: "2023/2024",
    issfTeamId: 56880,
    teamId: "649064237f8d0dc994a8462d",
    teamDisplayName: "NŠK 1922 Bratislava",
    nikeSutazId: 13821,
    nikeTeamID: 288288,
  },
  {
    issfSutazId: 4527,
    sutazID: "647d80567b634444d1e00fd5",
    sutazName: "Majstrovstvá regiónu - IV. liga BFZ",
    sutazRocnik: "2023/2024",
    issfTeamId: 56900,
    teamId: "649065187f8d0dc994a93a43",
    teamDisplayName: "OFK Dunajská Lužná",
    nikeSutazId: 13821,
    nikeTeamID: 6803,
  },
  {
    issfSutazId: 4527,
    sutazID: "647d80567b634444d1e00fd5",
    sutazName: "Majstrovstvá regiónu - IV. liga BFZ",
    sutazRocnik: "2023/2024",
    issfTeamId: 59534,
    teamId: "6499419feebe726b0437a3d3",
    teamDisplayName: "PŠC Pezinok",
    nikeSutazId: 13821,
    nikeTeamID: 19290,
  },
  {
    issfSutazId: 4527,
    sutazID: "647d80567b634444d1e00fd5",
    sutazName: "Majstrovstvá regiónu - IV. liga BFZ",
    sutazRocnik: "2023/2024",
    issfTeamId: 56863,
    teamId: "649062377f8d0dc994a61d74",
    teamDisplayName: "SDM Domino",
    nikeSutazId: 13821,
    nikeTeamID: 10095,
  },
  {
    issfSutazId: 4527,
    sutazID: "647d80567b634444d1e00fd5",
    sutazName: "Majstrovstvá regiónu - IV. liga BFZ",
    sutazRocnik: "2023/2024",
    issfTeamId: 59576,
    teamId: "649942d8eebe726b0438fea3",
    teamDisplayName: "TJ Rovinka",
    nikeSutazId: 13821,
    nikeTeamID: 19300,
  },
  {
    issfSutazId: 4527,
    sutazID: "647d80567b634444d1e00fd5",
    sutazName: "Majstrovstvá regiónu - IV. liga BFZ",
    sutazRocnik: "2023/2024",
    issfTeamId: 59490,
    teamId: "64994137eebe726b04371de9",
    teamDisplayName: "ŠK Bernolákovo",
    nikeSutazId: 13821,
    nikeTeamID: 19297,
  },
  {
    issfSutazId: 4527,
    sutazID: "647d80567b634444d1e00fd5",
    sutazName: "Majstrovstvá regiónu - IV. liga BFZ",
    sutazRocnik: "2023/2024",
    issfTeamId: 58655,
    teamId: "64953b0c7f8d0dc994454122",
    teamDisplayName: "ŠK Tomášov",
    nikeSutazId: 13821,
    nikeTeamID: 19285,
  },
  {
    issfSutazId: 4527,
    sutazID: "647d80567b634444d1e00fd5",
    sutazName: "Majstrovstvá regiónu - IV. liga BFZ",
    sutazRocnik: "2023/2024",
    issfTeamId: 60193,
    teamId: "64994fa1eebe726b04469d8b",
    teamDisplayName: "ŠK Vrakuňa Bratislava",
    nikeSutazId: 13821,
    nikeTeamID: 19278,
  },
  {
    issfSutazId: 4527,
    sutazID: "647d80567b634444d1e00fd5",
    sutazName: "Majstrovstvá regiónu - IV. liga BFZ",
    sutazRocnik: "2023/2024",
    issfTeamId: 57994,
    teamId: "649310217f8d0dc994562343",
    teamDisplayName: "Športový klub Nová Dedinka",
    nikeSutazId: 13821,
    nikeTeamID: 26138,
  },
  {
    issfSutazId: 4483,
    sutazID: "647a54b37b634444d16b355b",
    sutazName: "Niké Futsal Extraliga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56979,
    teamId: "648f51a57f8d0dc994754e40",
    teamDisplayName: "4FSC, o.z.",
    nikeSutazId: 11028,
    nikeTeamID: 219414,
  },
  {
    issfSutazId: 4483,
    sutazID: "647a54b37b634444d16b355b",
    sutazName: "Niké Futsal Extraliga",
    sutazRocnik: "2023/2024",
    issfTeamId: 61583,
    teamId: "64adaa174230a0a1311c14b2",
    teamDisplayName: "FSC Prievidza",
    nikeSutazId: 11028,
    nikeTeamID: 234089,
  },
  {
    issfSutazId: 4483,
    sutazID: "647a54b37b634444d16b355b",
    sutazName: "Niké Futsal Extraliga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56927,
    teamId: "648eeb497f8d0dc994d8c8b5",
    teamDisplayName: "Futsal Team Levice",
    nikeSutazId: 11028,
    nikeTeamID: 27023,
  },
  {
    issfSutazId: 4483,
    sutazID: "647a54b37b634444d16b355b",
    sutazName: "Niké Futsal Extraliga",
    sutazRocnik: "2023/2024",
    issfTeamId: 61696,
    teamId: "64c18a16cfdfa5c2bb294243",
    teamDisplayName: "MIBA Banská Bystrica",
    nikeSutazId: 11028,
    nikeTeamID: 34119,
  },
  {
    issfSutazId: 4483,
    sutazID: "647a54b37b634444d16b355b",
    sutazName: "Niké Futsal Extraliga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56273,
    teamId: "647f31027b634444d181ccd6",
    teamDisplayName: "Mimel Lučenec",
    nikeSutazId: 11028,
    nikeTeamID: 39765,
  },
  {
    issfSutazId: 4483,
    sutazID: "647a54b37b634444d16b355b",
    sutazName: "Niké Futsal Extraliga",
    sutazRocnik: "2023/2024",
    issfTeamId: 57013,
    teamId: "648fe8ea7f8d0dc994119c40",
    teamDisplayName: "MŠK MAYERSON Nové Zámky",
    nikeSutazId: 11028,
    nikeTeamID: 23442,
  },
  {
    issfSutazId: 4483,
    sutazID: "647a54b37b634444d16b355b",
    sutazName: "Niké Futsal Extraliga",
    sutazRocnik: "2023/2024",
    issfTeamId: 61579,
    teamId: "64ad3a614230a0a131a2b182",
    teamDisplayName: "Partizán Bardejov BŠK-futsal",
    nikeSutazId: 11028,
    nikeTeamID: 302562,
  },
  {
    issfSutazId: 4483,
    sutazID: "647a54b37b634444d16b355b",
    sutazName: "Niké Futsal Extraliga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56981,
    teamId: "648f51b17f8d0dc994755fde",
    teamDisplayName: "Pinerola 1994 Futsal Academy",
    nikeSutazId: 11028,
    nikeTeamID: 9576,
  },
  {
    issfSutazId: 4483,
    sutazID: "647a54b37b634444d16b355b",
    sutazName: "Niké Futsal Extraliga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56444,
    teamId: "6484db0a7b634444d1cedaf5",
    teamDisplayName: "Podpor Pohyb Košice",
    nikeSutazId: 11028,
    nikeTeamID: 264906,
  },
  {
    issfSutazId: 4483,
    sutazID: "647a54b37b634444d16b355b",
    sutazName: "Niké Futsal Extraliga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56989,
    teamId: "648f51ca7f8d0dc994758348",
    teamDisplayName: "ŠK Makroteam Žilina",
    nikeSutazId: 11028,
    nikeTeamID: 19399,
  },
  {
    issfSutazId: 4581,
    sutazID: "64997173eebe726b04698003",
    sutazName: "Niké liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56209,
    teamId: "64820c537b634444d123d9f0",
    teamDisplayName: "AS Trenčín",
    nikeSutazId: 30,
    nikeTeamID: 300,
  },
  {
    issfSutazId: 4581,
    sutazID: "64997173eebe726b04698003",
    sutazName: "Niké liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56115,
    teamId: "64822dc07b634444d145c4e0",
    teamDisplayName: "FC KOŠICE",
    nikeSutazId: 30,
    nikeTeamID: 299,
  },
  {
    issfSutazId: 4581,
    sutazID: "64997173eebe726b04698003",
    sutazName: "Niké liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56128,
    teamId: "6479d8737b634444d1ed09c7",
    teamDisplayName: "FC Spartak Trnava",
    nikeSutazId: 30,
    nikeTeamID: 304,
  },
  {
    issfSutazId: 4581,
    sutazID: "64997173eebe726b04698003",
    sutazName: "Niké liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56433,
    teamId: "64997173eebe726b04697fa7",
    teamDisplayName: "FC ViOn Zlaté Moravce",
    nikeSutazId: 30,
    nikeTeamID: 1200,
  },
  {
    issfSutazId: 4581,
    sutazID: "64997173eebe726b04698003",
    sutazName: "Niké liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56262,
    teamId: "64822d5b7b634444d145664c",
    teamDisplayName: "FK DAC 1904 Dunajská Streda",
    nikeSutazId: 30,
    nikeTeamID: 702,
  },
  {
    issfSutazId: 4581,
    sutazID: "64997173eebe726b04698003",
    sutazName: "Niké liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56305,
    teamId: "648ad89e7b634444d1e39007",
    teamDisplayName: "FK Železiarne Podbrezová",
    nikeSutazId: 30,
    nikeTeamID: 868,
  },
  {
    issfSutazId: 4581,
    sutazID: "64997173eebe726b04698003",
    sutazName: "Niké liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56103,
    teamId: "649183a67f8d0dc994c2286f",
    teamDisplayName: "MFK Dukla Banská Bystrica",
    nikeSutazId: 30,
    nikeTeamID: 301,
  },
  {
    issfSutazId: 4581,
    sutazID: "64997173eebe726b04698003",
    sutazName: "Niké liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56757,
    teamId: "649049637f8d0dc9948908fe",
    teamDisplayName: "MFK Ružomberok",
    nikeSutazId: 30,
    nikeTeamID: 306,
  },
  {
    issfSutazId: 4581,
    sutazID: "64997173eebe726b04698003",
    sutazName: "Niké liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56393,
    teamId: "648af7637b634444d1004090",
    teamDisplayName: "MFK Skalica",
    nikeSutazId: 30,
    nikeTeamID: 9864,
  },
  {
    issfSutazId: 4581,
    sutazID: "64997173eebe726b04698003",
    sutazName: "Niké liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56229,
    teamId: "648af6657b634444d1ff56ce",
    teamDisplayName: "MFK Zemplín Michalovce",
    nikeSutazId: 30,
    nikeTeamID: 2474,
  },
  {
    issfSutazId: 4581,
    sutazID: "64997173eebe726b04698003",
    sutazName: "Niké liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56372,
    teamId: "648af9257b634444d101ddb6",
    teamDisplayName: "MŠK Žilina",
    nikeSutazId: 30,
    nikeTeamID: 7843,
  },
  {
    issfSutazId: 4581,
    sutazID: "64997173eebe726b04698003",
    sutazName: "Niké liga",
    sutazRocnik: "2023/2024",
    issfTeamId: 56391,
    teamId: "64940a947f8d0dc99437f428",
    teamDisplayName: "ŠK Slovan Bratislava futbal",
    nikeSutazId: 30,
    nikeTeamID: 294,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 59557,
    teamId: "6497dac0eebe726b04e76729",
    teamDisplayName: "TJD Príbelce",
    nikeSutazId: 76,
    nikeTeamID: 39244,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 58008,
    teamId: "649621a3eebe726b048698c8",
    teamDisplayName: "1. FC TATRAN Prešov",
    nikeSutazId: 76,
    nikeTeamID: 303,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 56587,
    teamId: "64989dc3eebe726b04b2b06e",
    teamDisplayName: "1. MFK Kežmarok",
    nikeSutazId: 76,
    nikeTeamID: 52341,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 56502,
    teamId: "649186eb7f8d0dc994c72772",
    teamDisplayName: "1.FK Svidník",
    nikeSutazId: 76,
    nikeTeamID: 32002,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 57046,
    teamId: "6492d2087f8d0dc99413eb0a",
    teamDisplayName: "AFC Nové Mesto nad Váhom",
    nikeSutazId: 76,
    nikeTeamID: 5444,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 56209,
    teamId: "64820c537b634444d123d9f0",
    teamDisplayName: "AS Trenčín",
    nikeSutazId: 76,
    nikeTeamID: 300,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 59718,
    teamId: "649948d9eebe726b043f60a3",
    teamDisplayName: "FC - Žolík Malacky",
    nikeSutazId: 76,
    nikeTeamID: 26061,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 56115,
    teamId: "64822dc07b634444d145c4e0",
    teamDisplayName: "FC KOŠICE",
    nikeSutazId: 76,
    nikeTeamID: 299,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 58794,
    teamId: "6496706eeebe726b04bec217",
    teamDisplayName: "FC LOKOMOTÍVA KOŠICE",
    nikeSutazId: 76,
    nikeTeamID: 872,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 58488,
    teamId: "649441867f8d0dc9946fc06b",
    teamDisplayName: "FC Petržalka",
    nikeSutazId: 76,
    nikeTeamID: 298,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 59123,
    teamId: "6495d26eeebe726b044471a0",
    teamDisplayName: "FC Rohožník",
    nikeSutazId: 76,
    nikeTeamID: 19289,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 57579,
    teamId: "6499415ceebe726b043746a8",
    teamDisplayName: "FC Slovan Galanta",
    nikeSutazId: 76,
    nikeTeamID: 8654,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 59263,
    teamId: "6499355aeebe726b042af16f",
    teamDisplayName: "FC Slovan Modra",
    nikeSutazId: 76,
    nikeTeamID: 26147,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 56128,
    teamId: "6479d8737b634444d1ed09c7",
    teamDisplayName: "FC Spartak Trnava",
    nikeSutazId: 76,
    nikeTeamID: 304,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 56433,
    teamId: "64997173eebe726b04697fa7",
    teamDisplayName: "FC ViOn Zlaté Moravce",
    nikeSutazId: 76,
    nikeTeamID: 1200,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 57756,
    teamId: "6493017d7f8d0dc99445d49a",
    teamDisplayName: "FC ŠTK 1914 Šamorín",
    nikeSutazId: 76,
    nikeTeamID: 7641,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 56891,
    teamId: "649065717f8d0dc994a991b0",
    teamDisplayName: "FK Beluša",
    nikeSutazId: 76,
    nikeTeamID: 19247,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 56262,
    teamId: "64822d5b7b634444d145664c",
    teamDisplayName: "FK DAC 1904 Dunajská Streda",
    nikeSutazId: 76,
    nikeTeamID: 702,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 58987,
    teamId: "64962338eebe726b048825c4",
    teamDisplayName: "FK Dubnica nad Váhom",
    nikeSutazId: 76,
    nikeTeamID: 295,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 57196,
    teamId: "64940ae67f8d0dc994384049",
    teamDisplayName: "FK Inter Bratislava",
    nikeSutazId: 76,
    nikeTeamID: 870,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 57690,
    teamId: "6491c2d77f8d0dc99409764e",
    teamDisplayName: "FK Kechnec",
    nikeSutazId: 76,
    nikeTeamID: 32000,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 56291,
    teamId: "64940ab07f8d0dc994380fb0",
    teamDisplayName: "FK POHRONIE Žiar nad Hronom Dolná Ždaňa",
    nikeSutazId: 76,
    nikeTeamID: 4503,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 59469,
    teamId: "6497f167eebe726b04fab062",
    teamDisplayName: "FK Poprad",
    nikeSutazId: 76,
    nikeTeamID: 5447,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 56516,
    teamId: "649187207f8d0dc994c7665f",
    teamDisplayName: "FK Rača Bratislava",
    nikeSutazId: 76,
    nikeTeamID: 5445,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 56970,
    teamId: "6494085d7f8d0dc99435b1ca",
    teamDisplayName: "FK Slovan Duslo Šaľa",
    nikeSutazId: 76,
    nikeTeamID: 70438,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 57226,
    teamId: "649044ea7f8d0dc99483d2a6",
    teamDisplayName: "FK Čaňa",
    nikeSutazId: 76,
    nikeTeamID: 1202,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 56305,
    teamId: "648ad89e7b634444d1e39007",
    teamDisplayName: "FK Železiarne Podbrezová",
    nikeSutazId: 76,
    nikeTeamID: 868,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 59041,
    teamId: "6499329deebe726b04283535",
    teamDisplayName: "FTC Fiľakovo",
    nikeSutazId: 76,
    nikeTeamID: 19562,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 56116,
    teamId: "6479d85a7b634444d1ecef88",
    teamDisplayName: "Futbalový klub Humenné",
    nikeSutazId: 76,
    nikeTeamID: 297,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 56283,
    teamId: "648af8cc7b634444d1018c0b",
    teamDisplayName: "Futbalový klub Podkonice",
    nikeSutazId: 76,
    nikeTeamID: 221933,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 56556,
    teamId: "649017f17f8d0dc9944a8433",
    teamDisplayName: "Futbalový klub Spišská Nová Ves",
    nikeSutazId: 76,
    nikeTeamID: 5449,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 56178,
    teamId: "64820c197b634444d123a0ba",
    teamDisplayName: "Futbalový klub Čadca",
    nikeSutazId: 76,
    nikeTeamID: 3539,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 56109,
    teamId: "647904787b634444d14858e0",
    teamDisplayName: "KFC Komárno",
    nikeSutazId: 76,
    nikeTeamID: 24580,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 57625,
    teamId: "649409677f8d0dc99436ddc1",
    teamDisplayName: "MFK Dolný Kubín",
    nikeSutazId: 76,
    nikeTeamID: 4506,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 56103,
    teamId: "649183a67f8d0dc994c2286f",
    teamDisplayName: "MFK Dukla Banská Bystrica",
    nikeSutazId: 76,
    nikeTeamID: 301,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 58826,
    teamId: "64953dfc7f8d0dc99447e709",
    teamDisplayName: "MFK Rusovce",
    nikeSutazId: 76,
    nikeTeamID: 19291,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 56757,
    teamId: "649049637f8d0dc9948908fe",
    teamDisplayName: "MFK Ružomberok",
    nikeSutazId: 76,
    nikeTeamID: 306,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 56393,
    teamId: "648af7637b634444d1004090",
    teamDisplayName: "MFK Skalica",
    nikeSutazId: 76,
    nikeTeamID: 9864,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 56969,
    teamId: "649168577f8d0dc994a33af9",
    teamDisplayName: "MFK Slovan Sabinov",
    nikeSutazId: 76,
    nikeTeamID: 219255,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 57733,
    teamId: "649300e67f8d0dc99444fbf2",
    teamDisplayName: "MFK Snina",
    nikeSutazId: 76,
    nikeTeamID: 6804,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 56914,
    teamId: "648ea0357f8d0dc99470b4b3",
    teamDisplayName: "MFK Spartak Medzev",
    nikeSutazId: 76,
    nikeTeamID: 19556,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 56531,
    teamId: "6490135d7f8d0dc99444acd3",
    teamDisplayName: "MFK Tatran Liptovský Mikuláš",
    nikeSutazId: 76,
    nikeTeamID: 4115,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 56129,
    teamId: "64902d6b7f8d0dc994655a35",
    teamDisplayName: "MFK Vranov nad Topľou",
    nikeSutazId: 76,
    nikeTeamID: 1492,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 56229,
    teamId: "648af6657b634444d1ff56ce",
    teamDisplayName: "MFK Zemplín Michalovce",
    nikeSutazId: 76,
    nikeTeamID: 2474,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 57536,
    teamId: "64953ed37f8d0dc99448b1b8",
    teamDisplayName: "MFK Zvolen",
    nikeSutazId: 76,
    nikeTeamID: 6805,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 57531,
    teamId: "6492d3687f8d0dc994154d69",
    teamDisplayName: "MŠK FOMAT Martin",
    nikeSutazId: 76,
    nikeTeamID: 1231,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 58699,
    teamId: "6495d8afeebe726b044aa1d8",
    teamDisplayName: "MŠK Kysucké Nové Mesto",
    nikeSutazId: 76,
    nikeTeamID: 19550,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 56578,
    teamId: "64901cdc7f8d0dc99450b9b7",
    teamDisplayName: "MŠK NOVOHRAD Lučenec",
    nikeSutazId: 76,
    nikeTeamID: 19563,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 58967,
    teamId: "649621e2eebe726b0486ced0",
    teamDisplayName: "MŠK Námestovo",
    nikeSutazId: 76,
    nikeTeamID: 10096,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 56319,
    teamId: "64822dcd7b634444d145d104",
    teamDisplayName: "MŠK Považská Bystrica",
    nikeSutazId: 76,
    nikeTeamID: 8797,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 56306,
    teamId: "64822db27b634444d145b949",
    teamDisplayName: "MŠK Púchov",
    nikeSutazId: 76,
    nikeTeamID: 865,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 56899,
    teamId: "6490650d7f8d0dc994a9307f",
    teamDisplayName: "MŠK Rimavská Sobota",
    nikeSutazId: 76,
    nikeTeamID: 2244,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 57153,
    teamId: "6494a62b7f8d0dc994cf6bbc",
    teamDisplayName: "MŠK Spišské Podhradie",
    nikeSutazId: 76,
    nikeTeamID: 26156,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 56345,
    teamId: "648ad6527b634444d1e1643b",
    teamDisplayName: "MŠK Tesla Stropkov",
    nikeSutazId: 76,
    nikeTeamID: 4505,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 56372,
    teamId: "648af9257b634444d101ddb6",
    teamDisplayName: "MŠK Žilina",
    nikeSutazId: 76,
    nikeTeamID: 7843,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 56880,
    teamId: "649064237f8d0dc994a8462d",
    teamDisplayName: "NŠK 1922 Bratislava",
    nikeSutazId: 76,
    nikeTeamID: 288288,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 57975,
    teamId: "649440397f8d0dc9946e66f3",
    teamDisplayName: "OFK Baník Lehota pod Vtáčnikom",
    nikeSutazId: 76,
    nikeTeamID: 29908,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 56900,
    teamId: "649065187f8d0dc994a93a43",
    teamDisplayName: "OFK Dunajská Lužná",
    nikeSutazId: 76,
    nikeTeamID: 6803,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 57590,
    teamId: "64953b837f8d0dc99445a3f6",
    teamDisplayName: "OFK Malženice",
    nikeSutazId: 76,
    nikeTeamID: 12243,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 56557,
    teamId: "64902ebd7f8d0dc994675e93",
    teamDisplayName: "OK Častkovce",
    nikeSutazId: 76,
    nikeTeamID: 19250,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 57014,
    teamId: "649067ef7f8d0dc994ac5507",
    teamDisplayName: "OŠK BEŠEŇOVÁ",
    nikeSutazId: 76,
    nikeTeamID: 29302,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 58549,
    teamId: "649741e9eebe726b04725188",
    teamDisplayName: "OŠK Pavlovce nad Uhom",
    nikeSutazId: 76,
    nikeTeamID: 39249,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 59403,
    teamId: "6496e14eeebe726b0419a2f2",
    teamDisplayName: "OŠK Rudňany",
    nikeSutazId: 76,
    nikeTeamID: 26163,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 56183,
    teamId: "6494416c7f8d0dc9946fa6f5",
    teamDisplayName: "Partizán Bardejov BŠK",
    nikeSutazId: 76,
    nikeTeamID: 7798,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 59534,
    teamId: "6499419feebe726b0437a3d3",
    teamDisplayName: "PŠC Pezinok",
    nikeSutazId: 76,
    nikeTeamID: 19290,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 59051,
    teamId: "6496b57beebe726b04f5ed93",
    teamDisplayName: "RSC HAMSIK ACADEMY Banská Bystrica o.z.",
    nikeSutazId: 76,
    nikeTeamID: 288291,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 56863,
    teamId: "649062377f8d0dc994a61d74",
    teamDisplayName: "SDM Domino",
    nikeSutazId: 76,
    nikeTeamID: 10095,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 58880,
    teamId: "64962353eebe726b048838ff",
    teamDisplayName: "SLAVOJ TREBIŠOV",
    nikeSutazId: 76,
    nikeTeamID: 1201,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 59071,
    teamId: "64993196eebe726b04273e00",
    teamDisplayName: "Slávia TU Košice",
    nikeSutazId: 76,
    nikeTeamID: 32001,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 56270,
    teamId: "64822d677b634444d1456fc2",
    teamDisplayName: "Spartak Myjava",
    nikeSutazId: 76,
    nikeTeamID: 1860,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 58861,
    teamId: "6496209beebe726b0485eac0",
    teamDisplayName: "Stará Ľubovňa Redfox Football Club",
    nikeSutazId: 76,
    nikeTeamID: 26187,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 58424,
    teamId: "64942af77f8d0dc99458a3e4",
    teamDisplayName: "TJ Baník Kalinovo",
    nikeSutazId: 76,
    nikeTeamID: 8796,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 59170,
    teamId: "64993334eebe726b0428c324",
    teamDisplayName: "TJ Družstevník Veľké Ludince",
    nikeSutazId: 76,
    nikeTeamID: 19263,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 60057,
    teamId: "64988898eebe726b0499efb4",
    teamDisplayName: "TJ Mladosť Kalša",
    nikeSutazId: 76,
    nikeTeamID: 26161,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 59576,
    teamId: "649942d8eebe726b0438fea3",
    teamDisplayName: "TJ Rovinka",
    nikeSutazId: 76,
    nikeTeamID: 19300,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 58867,
    teamId: "649620ceeebe726b04860a01",
    teamDisplayName: "TJ Sokol Medzibrod",
    nikeSutazId: 76,
    nikeTeamID: 24596,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 56983,
    teamId: "649066327f8d0dc994aa7384",
    teamDisplayName: "TJ Sokol Zubrohlava",
    nikeSutazId: 76,
    nikeTeamID: 39246,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 57456,
    teamId: "649279257f8d0dc994b6f112",
    teamDisplayName: "TJ Tatran Krásno nad Kysucou",
    nikeSutazId: 76,
    nikeTeamID: 19553,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 59288,
    teamId: "64993647eebe726b042be063",
    teamDisplayName: "TJ Tatran Oravské Veselé",
    nikeSutazId: 76,
    nikeTeamID: 19317,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 58837,
    teamId: "64962053eebe726b0485bec0",
    teamDisplayName: "Telovýchovná jednota JEDNOTA Bánová",
    nikeSutazId: 76,
    nikeTeamID: 5448,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 59982,
    teamId: "64987a26eebe726b0487fccd",
    teamDisplayName: "ŠK Badín, občianske združenie",
    nikeSutazId: 76,
    nikeTeamID: 52330,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 59490,
    teamId: "64994137eebe726b04371de9",
    teamDisplayName: "ŠK Bernolákovo",
    nikeSutazId: 76,
    nikeTeamID: 19297,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 57633,
    teamId: "649300127f8d0dc99443faa4",
    teamDisplayName: "ŠK Javorník Makov",
    nikeSutazId: 76,
    nikeTeamID: 18122,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 56404,
    teamId: "64993584eebe726b042b2164",
    teamDisplayName: "ŠK Odeva Lipany",
    nikeSutazId: 76,
    nikeTeamID: 4507,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 59904,
    teamId: "64985b89eebe726b046534e3",
    teamDisplayName: "ŠK Prameň Kováčová",
    nikeSutazId: 76,
    nikeTeamID: 19304,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 56391,
    teamId: "64940a947f8d0dc99437f428",
    teamDisplayName: "ŠK Slovan Bratislava futbal",
    nikeSutazId: 76,
    nikeTeamID: 294,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 58655,
    teamId: "64953b0c7f8d0dc994454122",
    teamDisplayName: "ŠK Tomášov",
    nikeSutazId: 76,
    nikeTeamID: 19285,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 57027,
    teamId: "649066667f8d0dc994aaaad9",
    teamDisplayName: "ŠK Tvrdošín",
    nikeSutazId: 76,
    nikeTeamID: 19551,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 60193,
    teamId: "64994fa1eebe726b04469d8b",
    teamDisplayName: "ŠK Vrakuňa Bratislava",
    nikeSutazId: 76,
    nikeTeamID: 19278,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 57214,
    teamId: "649185f07f8d0dc994c5d5f1",
    teamDisplayName: "ŠKF Sereď",
    nikeSutazId: 76,
    nikeTeamID: 6802,
  },
  {
    issfSutazId: 4350,
    sutazID: "64784fa27b634444d1943186",
    sutazName: "Slovnaft Cup",
    sutazRocnik: "2023/2024",
    issfTeamId: 57994,
    teamId: "649310217f8d0dc994562343",
    teamDisplayName: "Športový klub Nová Dedinka",
    nikeSutazId: 76,
    nikeTeamID: 26138,
  },
];
