import styled from 'styled-components';
import { rem } from 'polished';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import EventWidgets from '../../../components/Widgets/EventWidgets';
import React from 'react';
import GossipDetailTopBar from './GossipDetailTopBar';
import { ContentContainer } from '../../../components/v2/ContentContainer';
import __ from '../../../utilities/__';
import Icon from '../../../components/Icon';
import Link from '../../../components/Link';
import { getGossipsBaseUrl } from '../utils';
import GossipBottomItems from './GossipBottomItems';
import { FeedItemInCache } from './features';
import Comment from '../../../containers/PetitpressComments';

const Wrapper = styled(ContentContainer)`
  position: relative;
  border: none;
  box-shadow: none;
  margin: ${rem(24)} ${rem(-16)};
  overflow: unset;
  :before {
    display: block;
    content: '';
    width: 100%;
    height: ${rem(24)};
    position: absolute;
    top: ${rem(-24)};
    left: 0;
    background-color: #f1f1f1;
  }
  :after {
    display: block;
    content: '';
    width: 100%;
    height: ${rem(24)};
    position: absolute;
    bottom: ${rem(-24)};
    left: 0;
    background-color: #f1f1f1;
  }
  ${mb('s')} {
    border-radius: ${({ theme }) => rem(theme.app.borderRadius)};
    border: 1px solid ${({ theme }) => theme.color.darkBorder};
    box-shadow: 0px 2px 4px ${({ theme }) => theme.color.shadowColor};
    margin: 0;
    :before {
      display: none;
    }
    :after {
      display: none;
    }
  }
`;

const CardWrapper = styled.div`
  background-color: #f1f1f1;
  ${mb('s')} {
    background-color: transparent;
  }
  > * {
    background-color: white;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  font-size: ${rem(11)};
  line-height: 1.3;
  font-weight: 700;
  text-transform: uppercase;
  padding: ${rem(16)};
  border-bottom: 1px solid ${({ theme }) => theme.color.lightBorder};
  border-radius: ${rem(8)} ${rem(8)} 0 0;
  ${mb('s')} {
    font-size: ${rem(12)};
    padding: ${rem(24)};
  }
`;

const LinkBack = styled(Link)`
  display: flex;
  align-items: center;
`;

const Chevron = styled(Icon)`
  transform: rotate(90deg);
  margin-right: ${rem(8)};
`;

const Content = styled.div<{ $hasDiscussion: boolean }>`
  line-height: 1.3;
  padding: ${rem(16)};
  font-size: ${rem(16)};
  ${mb('s')} {
    padding: ${rem(24)};
    font-size: ${rem(18)};
  }
  border-left: none;
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.color.lightBorder};
  ${mb('s')} {
    border-left: ${rem(4)} solid ${({ theme }) => theme.color.primary};
    border-bottom: ${rem(1)} solid ${({ theme }) => theme.color.lightBorder};
    border-radius: ${({ $hasDiscussion }) =>
      $hasDiscussion ? `0` : `0 0 ${rem(8)} ${rem(8)}`};
  }
`;

const CommentsWrapper = styled.div`
  padding: ${rem(16)};
  border-radius: 0 0 ${rem(8)} ${rem(8)};
  ${mb('s')} {
    padding: ${rem(24)};
  }
`;

export const TopBarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${rem(12)};
  font-size: ${rem(12)};
  color: ${({ theme }) => theme.color.secondaryFontColor};
  line-height: 1.3;
  ${mb('m')} {
    font-size: ${rem(14)};
  }
  font-weight: 600;
`;

export const MetaWrapper = styled.div`
  font-size: ${rem(14)};
`;

const Title = styled.h1`
  margin: ${rem(16)} 0;
  font-size: ${rem(20)};
  ${mb('s')} {
    font-size: ${rem(30)};
    margin: ${rem(24)} 0;
  }
  color: ${({ theme }) => theme.app.textColor};
  font-weight: 700;
  font-family: pt-serif-pro, serif;
`;

export const SmartTags = styled.div`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: ${rem(16)} 0 ${rem(24)} 0;
  gap: ${rem(4)};
`;

export const SmartTag = styled.a`
  display: flex;
  align-items: center;
  font-size: ${rem(11)};
  line-height: 1.4;
  color: ${({ theme }) => theme.color.textColor};
  font-weight: 600;
  padding: 0 ${rem(12)};
  border: ${rem(1)} solid ${({ theme }) => theme.color.lightBorder};
  border-radius: ${rem(12)};
  height: ${rem(24)};
`;

type GossipDetailBase = Pick<
  FeedItemInCache,
  | 'smarttags'
  | 'date'
  | 'extendedDescription'
  | 'title'
  | 'authors'
  | 'discussion'
>;

interface OwnProps extends GossipDetailBase {
  className?: string;
  detailUrl: string;
}

const GossipDetail: React.FC<OwnProps> = ({
  className,
  date,
  title,
  extendedDescription,
  smarttags,
  authors,
  detailUrl,
  discussion,
}) => {
  return (
    <Wrapper className={className}>
      <CardWrapper>
        <Header>
          <LinkBack to={getGossipsBaseUrl({ trailingSlash: true })}>
            <Chevron name="small-chevron" size="s" />
            {__('Futbalové pikošky')}
          </LinkBack>
        </Header>
        <Content $hasDiscussion={!!discussion}>
          <GossipDetailTopBar date={date} authors={authors ?? []} />
          <Title>{title}</Title>
          {(extendedDescription ?? []).length > 0 && (
            <EventWidgets items={extendedDescription ?? []} />
          )}
          {smarttags.length > 0 && (
            <SmartTags>
              {smarttags?.map((tag) => (
                <SmartTag
                  href={tag.url}
                  onClick={(event) => event.stopPropagation()}
                >
                  {tag.label}
                </SmartTag>
              ))}
            </SmartTags>
          )}
          <GossipBottomItems
            detailUrl={detailUrl}
            discussionPostCount={discussion?.postCount ?? 0}
            isDetail={true}
          />
        </Content>
        {discussion && (
          <CommentsWrapper>
            <Comment
              discussionType={discussion.type}
              discussionId={discussion.discussionId}
            />
          </CommentsWrapper>
        )}
      </CardWrapper>
    </Wrapper>
  );
};

export default GossipDetail;
