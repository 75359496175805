import { QueryNameSpace, createQueryFn } from '../../../query';
import { QueryFunctionContext } from '@tanstack/react-query';
import FeedsApi, { FeedItem } from '../../../api/FeedsApi';
import config from '../../../config';
import TagmanagerApi, {
  PublicSmartTagResponse,
} from '../../../api/TagmanagerApi';
import getBaseUrl from '../../../utilities/getBaseUrl';
import { smarttagsToUrl } from '@sportnet/tagmanager-connector';
import { LabelUrl } from '../features/getFeedItemsWithBanners';
import isBrowser from '../../../utilities/isBrowser';
import { getMediaManagerUrl } from '@sportnet/ui/lib/MediaManagerImage';

type FeedItemByIdParams = {
  id: string;
};

export type FeedItemInCache = Omit<FeedItem, 'smarttags'> & {
  smarttags: LabelUrl[];
  ogImageUrl?: string;
} 

const getFeedItemById = createQueryFn(
  QueryNameSpace.gossipDetail,
  async ({
    queryKey,
  }: QueryFunctionContext<[string] | [string, FeedItemByIdParams]>) => {
    const [, parameters] = queryKey;

    const feedItem = await FeedsApi.getFeedItemById(parameters?.id ?? '');

    const smarttagsUrl = smarttagsToUrl(feedItem.smarttags);

    const smarttagData = await TagmanagerApi.publicGetSmarttags(
      config.FEEDS_GOSSIP_APP_SPACE!,
      {
        smarttags: smarttagsUrl.split(','),
      },
    );

    const smarttagsMapped: { [key: string]: PublicSmartTagResponse } = {};

    smarttagData?.values?.forEach((tag) => {
      smarttagsMapped[`${tag.key}:${tag.value}`] = tag;
    });

    const newSmarttags: LabelUrl[] = [];

    feedItem.smarttags?.forEach((smarttag) => {
      smarttag.values?.forEach((value) => {
        const tag = smarttagsMapped[`${smarttag.key}:${value.key}`];

        if (tag) {
          newSmarttags.push({
            label: value.key,
            url: tag?.url ?? `${getBaseUrl()}/t/${tag.identifier}`,
          });
        }
      });
    });

    const feedItemWithCustomSmarttags: FeedItemInCache = {
      ...feedItem,
      smarttags: newSmarttags,
    };

    if (feedItem.ogImageBackground) {
      if (!isBrowser()) {
        const params = {
          text: `<span color="white" line_height="1" size="68pt">${feedItem.title}</span>`,
        };
        feedItemWithCustomSmarttags.ogImageUrl = `${getMediaManagerUrl(
          feedItem.ogImageBackground,
          0,
          0,
          'media',
        )}?query=${Buffer.from(JSON.stringify(params), 'utf-8').toString(
          'base64url',  // POZOR: musi byt `base64url` nie `base64`
        )}`;
      }
    }

    return {
      ...feedItemWithCustomSmarttags,
    };
  },
);

export type { FeedItemByIdParams };
export { getFeedItemById };
