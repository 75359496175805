import MediaManagerImage, {
  IPicture,
} from '@sportnet/ui/lib/MediaManagerImage';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { rem, transparentize } from 'polished';
import * as React from 'react';
import Button from '../../../../../components/Button';
import Icon from '../../../../../components/Icon';
import Link from '../../../../../components/Link';
import MaxWidthBox from '../../../../../components/MaxWidthBox';
import { SocialFollowUs } from '../../../../../components/Social';
import { ISocialIconName } from '../../../../../components/Social/SocialIcon';
import config from '../../../../../config';
import { SectionNode, SectionTreeType } from '../../../../../library/App';
import styled, { css } from '../../../../../theme/styled-components';
import getColor from '../../../../../utilities/getColor';
import getCopyrightText from '../../../../../utilities/getCopyrightText';
import getSectionUrl from '../../../../../utilities/getSectionUrl';
import __ from '../../../../../utilities/__';

const Wrapper = styled.footer<{ backgroundColor: string }>`
  display: flex;
  width: 100%;
  background: ${({ backgroundColor }) => backgroundColor};
  color: ${({ textColor }) => textColor};
  font-family: ${({ theme }) => theme.primaryFont.family };
`;

const NavigationWrapper = styled.div`
  display: flex;
  padding: 0 ${rem(16)};
  width: 100%;
  margin-bottom: ${rem(48)};

  ${mb('xxl')} {
    padding: 0;
  }
`;

const LogoWrapper = styled.div`
  display: none;

  ${mb('l')} {
    display: block;
    width: 17%;
  }
`;

const LogoLink = styled.a``;

const MediaManagerImageMainLogo = styled(MediaManagerImage)`
  &,
  & img {
    width: ${rem(32)};
    height: ${rem(32)};
  }
`;

const FooterWrapper = styled.div`
  ${mb('xxl')} {
    margin-left: ${rem(16)};
    margin-right: ${rem(16)};
  }
`;

const StyledMaxWidthBox = styled(MaxWidthBox)`
  margin-top: ${rem(24)};
  padding-left: 0;
  padding-right: 0;
`;

const Navigation = styled.nav`
  width: 100%;
  display: flex;
`;

const List = styled.ul<{ color: string }>`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
  width: 100%;

  & > li {
    padding: ${rem(8)} 0;
    width: 50%;
    flex: 0 0 auto;

    ${mb('l')} {
      width: 25%;
    }

    & > a {
      border: none;
      display: flex;
      cursor: pointer;
      align-items: center;
      font-family: ${({ theme }) => theme.primaryFont.family };
      font-weight: 400;
      text-decoration: none;
      color: inherit;
      outline: none;
      background: none;
      margin: 0;
      font-size: ${rem(14)};
      line-height: ${rem(19)};
      word-break: break-word;
      color: ${({ color }) => color};
      :hover,
      :active,
      :focus {
        text-decoration: underline;
      }
    }
  }
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  list-style-type: none;
  align-self: center;
  margin: 0;
  padding: 0;
  line-height: ${rem(19)};
`;

const MiddleFooterPart = styled.div`
  padding-bottom: ${rem(56)};
  border-bottom: ${rem(2)} solid ${transparentize(0.84, '#fff')};

  ${mb('l')} {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: ${rem(32)};
  }
`;

const FollowUsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${rem(16)};

  ${mb('m')} {
    flex-direction: row;
    align-items: center;
  }

  ${mb('xxl')} {
    padding: 0;
  }
`;

const FollowUsText = styled.div`
  font-size: ${rem(12)};
  margin-bottom: ${rem(24)};
  font-weight: 600;

  ${mb('m')} {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  flex-wrap: wrap;
  position: relative;
`;

const SocialIconText = styled.span`
  display: none;
  cursor: pointer;

  ${mb('xxs')} {
    display: block;
    font-size: ${rem(12)};
    margin-left: ${rem(8)};
    font-weight: 600;
  }
`;

const SocialIconWrapper = styled.div`
  margin-bottom: ${rem(16)};
  flex: 0 0 25%;

  ${mb('xxs')} {
    display: flex;
    align-items: center;
    flex: 0 0 50%;
  }

  ${mb('m')} {
    flex: 0 0 auto;
    margin-left: ${rem(24)};
    margin-bottom: 0;
  }
`;

const SocialIconLink = styled.a`
  ${mb('xxs')} {
    display: flex;
    align-items: center;
  }

  ${mb('m')} {
  }

  :hover ${SocialIconText} {
    text-decoration: underline;
  }
`;

const SocialIconBorder = styled.div`
  width: ${rem(40)};
  height: ${rem(40)};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: ${rem(1)} solid ${transparentize(0.84, '#fff')};
`;

const GoUpWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${rem(16)};
  margin-bottom: ${rem(56)};
  ${mb('l')} {
    margin-top: 0;
  }

  ${mb('xxl')} {
    margin-top: 0;
    padding: 0;
  }
`;

const GoUpButton = styled(Button)`
  && {
    width: 100%;
    background-color: ${transparentize(0.92, '#fff')};
    font-size: ${rem(14)};
    font-weight: 600;
    color: #fff;
    line-height: ${rem(19)};
    text-decoration: underline;
  }
`;

const GoUpIcon = styled(Icon)`
  margin-top: ${rem(1)};
  transform: rotate(180deg);
`;

const GoUpText = styled.div`
  margin-right: ${rem(8)};
`;

const SpacerCommonStyles = css<{ color: string }>`
  color: ${({ color }) => color};
  font-size: ${rem(12)};
  line-height: ${rem(17)};
  width: ${rem(7)};
  margin: 0 ${rem(8)};
  text-align: center;
  flex: 0 0 auto;
`;

const Spacer = styled.div`
  ${SpacerCommonStyles};
  display: block;
`;

const SpacerCopyright = styled.span`
  display: none;

  ${mb('l')} {
    ${SpacerCommonStyles}
    display: block;
  }
`;

const LinksWrapper = styled.div`
  a {
    color: ${({ textColor }) => textColor};
    font-size: ${rem(12)};
    white-space: nowrap;
    flex-shrink: 0;
    display: flex;

    :hover,
    :active,
    :focus {
      text-decoration: underline;
    }
  }
`;

const Copyright = styled.div`
  font-weight: 400;
  font-size: ${rem(12)};
  line-height: ${rem(17)};
`;

const BottomFooterPart = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: ${rem(32)} ${rem(16)};

  ${mb('l')} {
    justify-content: space-between;
    flex-direction: row-reverse;
    margin: ${rem(32)} ${rem(16)} ${rem(56)} ${rem(16)};
  }

  ${mb('xxl')} {
    margin: ${rem(32)} ${rem(0)} ${rem(56)} ${rem(0)};
  }
`;

const BottomFooterText = styled.div`
  display: flex;
  align-items: center;
`;

const BottomLinks = styled(LinksWrapper)`
  display: none;
  align-items: center;
  overflow-x: auto;
  ${mb('l')} {
    display: flex;
  }
`;

const BottomLinksMobile = styled(LinksWrapper)`
  display: flex;
  align-items: center;
  overflow-x: auto;
  margin-top: ${rem(40)};
  ${mb('l')} {
    display: none;
  }
`;

const LogoGrayLink = styled.a`
  display: flex;
  align-items: center;
`;

const MediaManagerImageGrayLogo = styled(MediaManagerImage)`
  &,
  & img {
    width: ${rem(24)};
    height: ${rem(24)};
    margin-right: ${rem(8)};
  }
`;

const scrollToTop = () => {
  try {
    window.scroll({ left: 0, top: 0, behavior: 'smooth' });
  } catch (e: any) {
    // fallback for browsers without support for ScrollToOptions
    window.scroll(0, 0);
  }
};

interface IOwnProps {
  configuration?: {
    textColor?: string;
    textColorNav?: string;
    backgroundColor?: string;
    textContent1?: string;
    logoColored?: { media_url: string; public_url: string };
    logoGray?: { media_url: string; public_url: string };
    socialFollowUsConfig?: React.ComponentProps<
      typeof SocialFollowUs
    >['config'];
    socialFollowUsVariant?: React.ComponentProps<
      typeof SocialFollowUs
    >['variant'];
  };
  sections?: SectionTreeType;
}

type IProps = IOwnProps;

const Footer: React.FC<IProps> = (props) => {
  const {
    sections = [],
    configuration: {
      textContent1 = '',
      socialFollowUsConfig,
      logoColored,
      logoGray,
    } = {},
  } = props;
  let {
    configuration: {
      textColor = '',
      textColorNav = '',
      backgroundColor = '',
    } = {},
  } = props;

  textColor = getColor(textColor, '#777777');
  textColorNav = getColor(textColorNav, '#333');
  backgroundColor = getColor(backgroundColor, '#FFFFFF');

  function renderSection(section: SectionNode) {
    return (
      <ListItem key={section._id}>
        <Link to={getSectionUrl('', section)}>{section.name}</Link>
      </ListItem>
    );
  }

  const sectionCount = (sections || []).length;

  const Links = (
    <>
      <a
        href="https://www.petitpress.sk/inzercia/inzercia-sme-sk/"
        target="_blank"
        rel="noopener noreferrer"
      >
        {__('Inzercia')}
      </a>
      <Spacer color={textColor}>|</Spacer>
      <a
        href="https://www.osobnyudaj.sk/informovanie/53117891/sk/zakladne-informacie"
        target="_blank"
        rel="noopener noreferrer"
      >
        {__('Ochrana osobných údajov')}
      </a>
      <Spacer color={textColor}>|</Spacer>
      {/* https://gitlab.bart.sk/sportnet/csm/sportnet.sme.sk/-/issues/226 */}
      <span className="js-consent-footer-link" />
    </>
  );

  return (
    <Wrapper backgroundColor={backgroundColor} textColor={textColor}>
      <StyledMaxWidthBox className="sptn-main-content">
        <FooterWrapper>
          <GoUpWrapper>
            <GoUpButton
              textColor={textColor}
              gradient={backgroundColor}
              onClick={() => scrollToTop()}
            >
              <GoUpText>{__('Späť na vrch')}</GoUpText>
              <GoUpIcon name="small-chevron" size="s" color="currentColor" />
            </GoUpButton>
          </GoUpWrapper>
          {sections.length > 0 && (
            <NavigationWrapper>
              <LogoWrapper>
                <LogoLink href={config.HOMEPAGE_URL}>
                  <MediaManagerImageMainLogo
                    picture={logoColored as IPicture}
                    naturalHeight={32}
                    naturalWidth={32}
                    script="resize"
                    webp={true}
                    loading="lazy"
                  />
                </LogoLink>
              </LogoWrapper>

              <Navigation sectionCount={sectionCount}>
                <List color={textColorNav}>{sections.map(renderSection)}</List>
              </Navigation>
            </NavigationWrapper>
          )}
          <MiddleFooterPart>
            <FollowUsWrapper>
              <FollowUsText>{__('Sledujte nás:')}</FollowUsText>
              <SocialIcons>
                {Object.values(socialFollowUsConfig || {}).length > 0 &&
                  Object.entries(socialFollowUsConfig!).map(
                    ([name, info], i) => (
                      <SocialIconWrapper key={i}>
                        <SocialIconLink
                          href={info.url || '#'}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <SocialIconBorder>
                            <Icon
                              name={name as ISocialIconName}
                              color={transparentize(0.68, '#FFFFFF')}
                              size={16}
                            />
                          </SocialIconBorder>
                          {info.text && (
                            <SocialIconText>{info.text}</SocialIconText>
                          )}
                        </SocialIconLink>
                      </SocialIconWrapper>
                    ),
                  )}
              </SocialIcons>
            </FollowUsWrapper>
            <FollowUsWrapper>
              <BottomLinksMobile>{Links}</BottomLinksMobile>
            </FollowUsWrapper>
          </MiddleFooterPart>
          <BottomFooterPart>
            <LogoGrayLink href={config.HOMEPAGE_URL}>
              <MediaManagerImageGrayLogo
                picture={logoGray as IPicture}
                naturalHeight={24}
                naturalWidth={24}
                script="resize"
                webp={true}
                loading="lazy"
              />
            </LogoGrayLink>

            <BottomFooterText>
              {textContent1 && (
                <>
                  <Copyright>{getCopyrightText(textContent1)}</Copyright>
                  <SpacerCopyright color={textColor}>|</SpacerCopyright>
                </>
              )}
              <BottomLinks>{Links}</BottomLinks>
            </BottomFooterText>
          </BottomFooterPart>
        </FooterWrapper>
      </StyledMaxWidthBox>
    </Wrapper>
  );
};

export default React.memo(Footer);
