import { mb } from '@sportnet/ui/lib/Themes/utilities';
import { MatchesPanelTeamProps } from '../../types/MatchesPanel';
import { rem } from 'polished';
import React from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Image = styled.img`
   object-fit: contain
`

const TeamInfo = styled.div`
  display: flex;
  align-items: center;
`;

const TeamLogoSrcWrapper = styled.div`
  display: flex;
  border-radius: 50%;
  overflow: hidden;
  margin-right: ${rem(8)};
`;

const TeamName = styled.span<{ $bold?: boolean }>`
  ${({ $bold }) =>
    !!$bold &&
    css`
      font-weight: bold;
    `};
`;

const TextAndScoreWrapper = styled.div<{
  $justifyEnd?: boolean;
  $marginRight?: number;
  hideOnMobile?: boolean;
  $maxWidth?: number;
}>`
  ${({ hideOnMobile = false }) =>
    hideOnMobile
      ? css`
          display: none;
          ${mb('s')} {
            display: flex;
          }
        `
      : css`
          display: flex;
        `};
  align-items: center;
  gap: ${rem(16)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${({ $justifyEnd = false }) =>
    $justifyEnd &&
    css`
      justify-content: end;
    `};
  ${({ $marginRight }) =>
    typeof $marginRight !== 'undefined' &&
    css`
      margin-right: ${rem($marginRight)};
    `};
  ${({ $maxWidth }) =>
    !!$maxWidth &&
    css`
      max-width: ${rem($maxWidth)};
      width: ${rem($maxWidth)};
    `};
`;

export const Text = styled.span<{
  $color?: 'red' | 'faded' | 'normal';
  $bold?: boolean;
  hideOnMobile?: boolean;
  $minWidth?: number;
}>`
  ${({ hideOnMobile = false }) =>
    hideOnMobile &&
    css`
      display: none;
      ${mb('s')} {
        display: inline;
      }
    `};
  ${({ theme, $color }) =>
    !!$color &&
    css`
      color: ${$color === 'red'
        ? theme.color.primaryColor
        : $color === 'faded'
        ? theme.color.secondaryFontColor
        : theme.color.primaryFontColor};
    `};
  ${({ $bold }) =>
    !!$bold &&
    css`
      font-weight: bold;
    `};
  ${({ $minWidth }) =>
    !!$minWidth &&
    css`
      min-width: ${rem($minWidth)};
    `};
`;

const OnlyOnMobile = styled.div`
  display: flex;
  ${mb('s')} {
    display: none;
  }
`;

const ScoreNote = styled.sup`
  font-size: ${rem(8)};
`;

type OwnProps = { className?: string } & MatchesPanelTeamProps;

export const Team: React.FC<OwnProps> = ({
  className,
  teamName,
  teamNameBold,
  teamLogoSrc,
  teamLogoAlt,
  score,
  scoreNote,
  scoreColor,
  scoreWeightBold,
  shouldRenderScore = false,
  text,
  textColor,
  textAtFirstPosition = false,
  matchHasScoreNote = false,
}) => {
  const scoreString = shouldRenderScore ? score || '-' : '';
  return (
    <Wrapper className={className}>
      <TeamInfo>
        {textAtFirstPosition && text && (
          <TextAndScoreWrapper
            $marginRight={16}
            $maxWidth={35}
            className="matchesPanel-match-text"
            hideOnMobile={true}
          >
            <Text $color={textColor}>{text}</Text>
          </TextAndScoreWrapper>
        )}
        <TeamLogoSrcWrapper>
          <Image
            src={teamLogoSrc}
            alt={teamLogoAlt}
            width={16}
            height={16}
          />
        </TeamLogoSrcWrapper>
        <TeamName $bold={teamNameBold}>{teamName}</TeamName>
      </TeamInfo>
      <TextAndScoreWrapper>
        {!textAtFirstPosition && text && (
          <Text $color={textColor} hideOnMobile={true}>
            {text}
          </Text>
        )}
        {!!scoreString ? (
          <Text
            $color={scoreColor}
            $bold={scoreWeightBold}
            $minWidth={matchHasScoreNote ? 30 : undefined}
          >
            {scoreString}
            {scoreNote && <ScoreNote>{scoreNote}</ScoreNote>}
          </Text>
        ) : (
          <OnlyOnMobile>
            <Text
              $color={scoreColor}
              $bold={scoreWeightBold}
              $minWidth={matchHasScoreNote ? 30 : undefined}
            >
              -
            </Text>
          </OnlyOnMobile>
        )}
      </TextAndScoreWrapper>
    </Wrapper>
  );
};
