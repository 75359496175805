import React from 'react';
import styled from 'styled-components';

const StyledImage = styled.img`
  border: 0;
  position: absolute;
  z-index: -1;
`;

interface OwnProps {
  className?: string;
  bn: string;
}

export const NikeLogoTrackingImage: React.FC<OwnProps> = ({
  className,
  bn,
}) => (
  <StyledImage
    className={className}
    src={`https://track.adform.net/adfserve/?bn=${bn};1x1inv=1;srctype=3;ord=[timestamp]`}
    width="1"
    height="1"
    alt={`Nike track ${bn}`}
  />
);
