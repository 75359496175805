import slugify from 'slugify';
import { FeedItemInCache } from './detail/features';
import getBaseUrl from '../../utilities/getBaseUrl';
import formatDate from '../../utilities/formatDate';

export const getFeedItemItemId = (id: string) => {
  return id.split(':').pop();
};

export const getFeedItemSlug = (title: FeedItemInCache['title']) => {
  return slugify(title, { lower: true, remove: /[*+~.()'"!:@]/g });
};

export const getGossipsBaseUrl = (
  opts: { trailingSlash: boolean } = { trailingSlash: false }
) => {
  return `/futbalnet/pikosky${opts.trailingSlash ? '/' : ''}`;
};

export const getFeedItemDetailUrl = (_id: string, title: string) => {
  return title
    ? getBaseUrl() +
        `${getGossipsBaseUrl()}/${getFeedItemItemId(_id)}/${getFeedItemSlug(
          title
        )}/`
    : '';
};

export const formatFeedItemDate = (date: Date) => {
  return formatDate(date, 'd. MMM\',\' yyyy HH:mm') 
};

export const getNumberOfCommetsLabel = (postCount: number) => {
  if (postCount === 1) {
    return 'komentár';
  } else if (postCount === 0 || postCount >= 5) {
    return 'komentárov';
  } else {
    return 'komentáre';
  }
};
